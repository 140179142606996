import * as actionTypes from './contactActionTypes';
import * as API from '../../api/contactApi';

export function contactSubmitStarted() {
  return {
    type: actionTypes.CONTACT_SUBMIT_STARTED,
  };
}

export function contactSubmitSucceeded() {
  return {
    type: actionTypes.CONTACT_SUBMIT_SUCCEEDED,
  };
}

export function contactSubmitFailed(error) {
  return {
    type: actionTypes.CONTACT_SUBMIT_FAILED,
    payload: { error },
  };
}

export function setContactValues(values) {
  return {
    type: actionTypes.SET_CONTACT_VALUES,
    payload: { values },
  };
}

export function submitContact() {
  return async (dispatch, getState) => {
    const {
      auth: { user },
      contact: { values },
    } = getState();
    dispatch(contactSubmitStarted());
    try {
      await API.contact({ token: user.token }, values);
      dispatch(contactSubmitSucceeded());
      dispatch(
        setContactValues({
          name: '',
          email: '',
          subject: '',
          message: '',
        })
      );
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(contactSubmitFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(contactSubmitFailed(error.detail));
      } else {
        dispatch(contactSubmitFailed(error.detail));
      }
    }
  };
}
