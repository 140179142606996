import React from 'react';
import { SignInForm } from '../../../auth';
import petrobrasLogo from './assets/petrobrasLogo.png';
import petrofoamLogo from './assets/petrofoamLogo.png';
import arrowDown from './assets/arrowDown.png';

import styles from './loginSection.module.css';

const LoginSectionContent = () => (
  <div className={styles['login-section']}>
    <div className={styles['login-section__gradient']} />

    <div className={styles['login-section__header']}>
      <img
        className={styles.header__logo}
        src={petrofoamLogo}
        alt="petroFOAM"
      />
      <div className={styles['header__link-container']}>
        <a href="#plataforma" className={styles.header__link}>
          A Plataforma
        </a>
        <a href="#avaliação" className={styles.header__link}>
          Avaliações
        </a>
      </div>
    </div>

    <SignInForm />

    <div className={styles['login-section__footer']}>
      <img
        className={styles.footer__logo}
        src={petrobrasLogo}
        alt="petrobras"
      />
      <div className={styles['footer__mouse-container']}>
        <span className={styles['footer__mouse-text']}>Conheça mais</span>
        <div className={styles['footer__mouse-icon']}>
          <img
            className={styles['footer__mouse-arrow-down']}
            src={arrowDown}
            alt="Seta para baixo"
          />
        </div>
      </div>
      <div className={styles.footer__wikki}>Desenvolvido por Wikki Brasil</div>
    </div>
  </div>
);

export default LoginSectionContent;
