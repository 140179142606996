import React from 'react';
import useProjectList from './useProjectList';
import ProjectItem from './components/ProjectItem';

import styles from './ProjectList.module.css';
import ProjectOrCaseRemoveModal from './components/ProjectOrCaseRemoveModal';

const ProjectList = ({ projects, addNewCase, removeProjectCallback }) => {
  const {
    handleRemoveProject,
    isRemoving,
    setIsRemoving,
    removeProjectAction,
    currentProject,
  } = useProjectList(removeProjectCallback);

  return (
    <>
      <div className={styles.project_list_container}>
        <div className={styles.table}>
          <div className={styles.header}>
            <div>
              <p>Nome</p>
              <p>Descrição</p>
            </div>
          </div>
          <div className={styles.body}>
            {projects.length ? (
              projects.map(project => (
                <ProjectItem
                  key={project.id}
                  project={project}
                  handleRemoveProject={handleRemoveProject}
                  addNewCase={addNewCase}
                />
              ))
            ) : (
              <p className={styles.no_projects}>Sem projetos...</p>
            )}
          </div>
        </div>
      </div>
      {isRemoving && (
        <ProjectOrCaseRemoveModal
          type="project"
          projectOrCaseName={currentProject.name}
          removeHandler={removeProjectAction}
          closeModal={() => setIsRemoving(false)}
        />
      )}
    </>
  );
};

export default ProjectList;
