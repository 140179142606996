/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './solverCard.module.css';
import Rating from './Rating';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg';
import wellReservoirThumbnail from '../../assets/images/produtividade_thumbnail.png';
import acidPlugThumbnail from '../../assets/images/acidPlug_thumbnail.png';
import krelThumbnail from '../../assets/images/krel_thumbnail.jpg';
import kabsThumbnail from '../../assets/images/kabs_thumbnail.jpg';

const SolverCard = ({ solver, isExpanded, onExpand }) => {
  const {
    id,
    identifier,
    name,
    rating,
    total_comments: totalComments,
    created_at: createdAt,
    description,
  } = solver;

  const thumbnail = {
    WellReservoir: wellReservoirThumbnail,
    PlugAcidification: acidPlugThumbnail,
    Krel: krelThumbnail,
    Kabs: kabsThumbnail,
  }[identifier];

  return (
    <Link
      to={`/solvers/${id}`}
      className={`${classes.card} ${isExpanded ? classes.expanded : ''}`}
    >
      <div className={classes.image}>
        <img src={thumbnail} alt="solver" />
        <div className={classes.gradient} />
      </div>
      <div className={classes.content}>
        <div className={classes.details}>
          <div className={classes.name}>{name}</div>
          <div className={classes.rating}>
            <Rating rating={rating} />
            <div className={classes.comments}>({totalComments})</div>
          </div>
          <div className={classes.info}>
            {new Date(createdAt).getFullYear()}
            {' | '}
            Wikki Brasil
          </div>
        </div>
        <div className={classes.description}>{description}</div>
        <button
          type="button"
          className={classes['expand-button']}
          onClick={e => {
            e.preventDefault();
            onExpand();
          }}
        >
          <ExpandIcon />
        </button>
      </div>
    </Link>
  );
};

export default SolverCard;
