import React from 'react';

import styles from './LoadingBar.module.css';

function LoadingBar() {
  return (
    <div className={styles.loading_bar_container}>
      <span className={styles.loading_bar} />
    </div>
  );
}

export default LoadingBar;
