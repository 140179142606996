import { combineReducers } from 'redux';
import headerReducer from './header/reducer';
import leftPanelReducer from './leftPanel/leftPanelReducer';
import rightPanelReducer from './rightPanel/rightPanelReducer';

const uiReducer = combineReducers({
  header: headerReducer,
  leftPanel: leftPanelReducer,
  rightPanel: rightPanelReducer,
});

export default uiReducer;
