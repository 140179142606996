import * as actionTypes from './usersActionTypes';

const initialState = {
  isLoading: false,
  items: [],
  error: null,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USERS_STARTED:
      return {
        ...state,
        isLoading: true,
        items: [],
        error: null,
      };

    case actionTypes.FETCH_USERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        items: action.payload.users,
        error: null,
      };

    case actionTypes.FETCH_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        items: [],
        error: action.payload.error,
      };

    default:
      return state;
  }
}
