import React from 'react';

import MyFavorites from '../../favorites/MyFavorites';
import styles from './FavoritesPage.module.css';

function FavoritesPage() {
  return (
    <div className={styles.container_my_favorites}>
      <MyFavorites />
    </div>
  );
}

export default FavoritesPage;
