import React from 'react';
import { MdHighlightOff as CloseIcon } from 'react-icons/md';
import useNotificationItem from './useNotificationItem';

import styles from './NotificationItem.module.css';

const NotificationItem = ({
  notification,
  markAsRead,
  handleDeleteNotification,
}) => {
  const { id, message, messageType, read } = notification;

  const { cardColor, CardIcon, notReadStyle } = useNotificationItem(
    messageType,
    read
  );

  return (
    <div
      role="button"
      title={read ? 'Lido' : 'Clique para marcar como lido'}
      onClick={() => {
        if (!read) markAsRead(id);
      }}
      className={`${styles.notification_item} ${cardColor} ${notReadStyle}`}
      aria-hidden
    >
      <CardIcon />
      <p>{message}</p>
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          handleDeleteNotification(id);
        }}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default NotificationItem;
