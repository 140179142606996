import { combineReducers } from 'redux';

const createReducer = asyncReducers => {
  const appReducer = combineReducers({
    ...asyncReducers,
  });

  return (state, action) => {
    if (action.type === 'SIGNOUT_STARTED') {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
};

export default createReducer;
