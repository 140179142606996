export default function contactFormValidation(values) {
  const errors = {};


  if (!values.email) {
    errors.email = 'Email requerido';
  }
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Email deve ser válido';
  }

  if (!values.name) {
    errors.name = 'Nome requerido';
  }

  if (!values.subject) {
    errors.subject = 'Assunto requerido';
  }

  if (!values.message) {
    errors.message = 'Mensagem requerida';
  }

  return errors;
}
