import React from 'react';
import classes from './testimonialsSection.module.css';

const TestimonialsSectionContent = () => (
  <div id="avaliação" className={classes.sectionTestimonials}>
    <span className={classes.wrapper}>
      <div className={classes.title}>AVALIAÇÕES</div>
      <div className={classes.subtitle}> O que dizem os nossos usuários </div>
    </span>
  </div>
);

export default TestimonialsSectionContent;
