export const SWITCH_CURRENT_TAB = 'SWITCH_CURRENT_TAB';
export const TOGGLE_RIGHT_PANEL = 'TOGGLE_RIGHT_PANEL';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const MARK_NOTIFICATIONS_AS_READ_STARTED =
  'MARK_NOTIFICATIONS_AS_READ_STARTED';
export const MARK_NOTIFICATIONS_AS_READ_SUCCEEDED =
  'MARK_NOTIFICATIONS_AS_READ_SUCCEEDED';
export const MARK_NOTIFICATIONS_AS_READ_FAILED =
  'MARK_NOTIFICATIONS_AS_READ_FAILED';
export const DELETE_NOTIFICATION_STARTED = 'DELETE_NOTIFICATION_STARTED';
export const DELETE_NOTIFICATION_SUCCEEDED = 'DELETE_NOTIFICATION_SUCCEEDED';
export const DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED';

export const FETCH_SIMULATIONS = 'FETCH_SIMULATIONS';
export const ADD_SIMULATION = 'ADD_SIMULATION';
export const UPDATE_SIMULATION = 'UPDATE_SIMULATION';
export const STOP_SIMULATION_STARTED = 'STOP_SIMULATION_STARTED';
export const STOP_SIMULATION_SUCCEEDED = 'STOP_SIMULATION_SUCCEEDED';
export const STOP_SIMULATION_FAILED = 'STOP_SIMULATION_FAILED';
export const DELETE_SIMULATION_SUCCEEDED = 'DELETE_SIMULATION_SUCCEEDED';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
