import { read } from '../common/services/apiHandler';
import { axiosPrivate } from '../common/services/axios';

export async function readUsers(authorization) {
  const response = read({ auth: authorization, resource: '/users/' });

  return response;
}

export async function fetchUserPreferences() {
  const response = await axiosPrivate.get('/user_preferences/');

  return response?.data;
}
