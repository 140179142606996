import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AppRoutes from './routes';
import { fetchUserPreferences } from './api/usersApi';

import './App.css';

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchUserPreferences()
      .then(data => {
        dispatch({
          type: 'SIGNIN_AFTER_REFRESH_PAGE',
          payload: {
            user: {
              userId: data?.user?.id,
              userName:
                data?.user?.first_name === ''
                  ? data?.user?.username
                  : `${data?.user?.first_name} ${data?.user?.last_name}`,
              username: data?.user?.username,
              userAvatar: data?.user?.image,
              is_staff: data?.user?.username === 'admin' ? true : false,
            },
          },
        });
        if (data?.user?.username !== 'admin') {
          dispatch({
            type: 'SET_CURRENT_DISCIPLINE',
            payload: {
              discipline: data?.favorite_discipline || null,
            },
          });
          dispatch({
            type: 'FETCH_CATEGORIES_SUCCEEDED',
            payload: {
              categories: {
                items: data?.categories || [],
              },
            },
          });
        }
      })
      .finally(() => setIsLoading(false));

    return () => setIsLoading(false);
  }, []);

  return <div>{isLoading ? <span /> : <AppRoutes />}</div>;
}

export default App;
