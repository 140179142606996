import React, { Suspense } from 'react';
import {
  createBrowserRouter,
  Routes,
  Route,
  RouterProvider,
} from 'react-router-dom';

import LoadingBar from '../components/LoadingBar/LoadingBar';

import { HomePage, AdminPage } from '../pages';
import UserRoutes from './UserRoutes';
import AdminAuth from './AdminAuth';

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export default function AppRoutes() {
  return <RouterProvider router={router} />;
}

function Root() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingBar />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path="/admin"
        element={
          <Suspense fallback={<LoadingBar />}>
            <AdminAuth>
              <AdminPage />
            </AdminAuth>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingBar />}>
            <UserRoutes />
          </Suspense>
        }
      />
    </Routes>
  );
}
