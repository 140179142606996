import { useSelector, useDispatch } from 'react-redux';
import { socketConnect } from '../../state/ui/rightPanel/rightPanelActions';

function useHeader() {
  const { notifications, simulations, connectionStatus } = useSelector(
    state => state.ui.rightPanel
  );
  const { user } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  if (connectionStatus === 'SOCKET_DISCONNECTED') {
    const socketURL =
      user === null
        ? `${window.REACT_APP_PETROFOAM_WS_URL}/ws/news/?token=`
        : `${window.REACT_APP_PETROFOAM_WS_URL}/ws/news/?token=${user.token}`;
    dispatch(socketConnect(socketURL));
  }

  const hasSimulationRunning =
    simulations.items.filter(
      simulation =>
        simulation.jobStatus === 'NA FILA PARA PRÉ-PROCESSAR' ||
        simulation.jobStatus === 'NA FILA' ||
        simulation.jobStatus === 'EM PRÉ-PROCESSAMENTO' ||
        simulation.jobStatus === 'SIMULANDO' ||
        simulation.jobStatus === 'FINALIZANDO'
    ).length > 0;

  const unreadNotifications = notifications.items.filter(
    notification => !notification.read
  ).length;

  const headerTitle = useSelector(state => state.ui.header.title);

  return {
    hasSimulationRunning,
    unreadNotifications,
    connectionStatus,
    headerTitle,
  };
}

export default useHeader;
