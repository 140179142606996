import React from 'react';
import {
  MdLink as ConnectedIcon,
  MdLinkOff as DisconnectedIcon,
} from 'react-icons/md';

import styles from './ConnectionStatusHeaderItem.module.css';

const ConnectionStatusHeaderItem = ({ connectionStatus }) => (
  <div className={styles.connection_status_icon}>
    {connectionStatus === 'SOCKET_CONNECTED' ? (
      <ConnectedIcon title="Conectado" color="#48B583" size={32} />
    ) : (
      <DisconnectedIcon title="Desconectado" color="#FF3737" size={32} />
    )}
  </div>
);

export default ConnectionStatusHeaderItem;
