import * as actionTypes from './categoriesActionTypes';
import * as api from '../../api/categoriesApi';
import * as favoritesApi from '../../api/favoritesApi';

export function fetchCategoriesStarted() {
  return {
    type: actionTypes.FETCH_CATEGORIES_STARTED,
  };
}

export function fetchCategoriesSucceeded(categories) {
  return {
    type: actionTypes.FETCH_CATEGORIES_SUCCEEDED,
    payload: {
      categories,
    },
  };
}

export function fetchCategoriesFailed(error) {
  return {
    type: actionTypes.FETCH_CATEGORIES_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchCategoriesByDiscipline(discipline) {
  return async dispatch => {
    dispatch(fetchCategoriesStarted());

    try {
      const data = await api.fetchCategoriesByDisciplineId(discipline.id);

      const categories = {
        items: data?.categories,
      };

      dispatch(fetchCategoriesSucceeded(categories));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchCategoriesFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchCategoriesFailed(error.detail));
      } else {
        dispatch(fetchCategoriesFailed(error.message));
      }
    }
  };
}

export function toggleFavoriteStarted() {
  return {
    type: actionTypes.TOGGLE_FAVORITE_STARTED,
  };
}

export function toggleFavoriteSucceeded(id) {
  return {
    type: actionTypes.TOGGLE_FAVORITE_SUCCEEDED,
    payload: {
      id,
    },
  };
}

export function toggleFavoriteFailed(error) {
  return {
    type: actionTypes.TOGGLE_FAVORITE_FAILED,
    payload: {
      error,
    },
  };
}

export function toggleFavorite(solverId) {
  return async (dispatch, getState) => {
    dispatch(toggleFavoriteStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await favoritesApi.toggleFavoriteSolver({ token: user.token }, solverId);
      dispatch(toggleFavoriteSucceeded(solverId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(toggleFavoriteFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(toggleFavoriteFailed(error.detail));
      } else {
        dispatch(toggleFavoriteFailed(error.message));
      }
    }
  };
}
