/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

setLocale({
  mixed: {
    notType: field => {
      switch (field.type) {
        case 'number':
          return field.label
            ? `${field.label} deve ser um número`
            : 'Deve ser um número';
        default:
          return `${field.label} não é válido`;
      }
    },
    required: field =>
      field.label
        ? `${field.label} é um campo obrigatório`
        : 'Campo obrigatório',
    oneOf: field => {
      if (field.resolved.length > 1) {
        return field.label
          ? `${
              field.label
            } deve ser um dos seguintes valores: ${field.resolved.join(', ')}`
          : `Deve ser um dos seguintes valores: ${field.resolved.join(', ')}`;
      }
      return field.label
        ? `${field.label} deve ser igual a ${field.resolved[0]}`
        : `Deve ser igual a ${field.resolved[0]}`;
    },
  },

  number: {
    min: field =>
      field.label
        ? `${field.label} deve ser maior ou igual a ${field.min}`
        : `Deve ser maior ou igual a ${field.min}`,
    max: field =>
      field.label
        ? `${field.label} deve ser menor ou igual a ${field.max}`
        : `Deve ser menor ou igual a ${field.max}`,
    lessThan: field =>
      field.label
        ? `${field.label} deve ser menor que ${field.less}`
        : `Deve ser menor que ${field.less}`,
    moreThan: field =>
      field.label
        ? `${field.label} deve ser maior que ${field.more}`
        : `Deve ser maior que ${field.more}`,
    integer: field =>
      field.label
        ? `${field.label} deve ser um número inteiro`
        : `Deve ser um número inteiro`,
  },
  array: {
    min: field =>
      field.label
        ? `${field.label} deve ter, pelo menos, ${field.min} entradas`
        : `Deve ter, pelo menos, ${field.min} entradas`,
    max: field =>
      field.label
        ? `${field.label} deve ter, no máximo, ${field.max} entradas`
        : `Deve ter , no máximo, ${field.max} entradas`,
  },
  string: {
    email: field =>
      field.label ? `${field.label} deve ser um email` : `Deve ser um email`,
  },
});
