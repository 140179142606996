import {
  MdOutlineDangerous as ErrorIcon,
  MdCheckCircleOutline as CheckIcon,
  MdUpdate as UpdateIcon,
  MdShare as ShareIcon,
  MdInfoOutline as InfoIcon,
} from 'react-icons/md';

import styles from './NotificationItem.module.css';

const useNotificationItem = (messageType, read) => {
  const icons = {
    STATUS: InfoIcon,
    RESULTS_UPDATED: UpdateIcon,
    COMPLETE: CheckIcon,
    SHARED_CASE: ShareIcon,
    FAILED: ErrorIcon,
  };

  const CardIcon = icons[messageType];

  const statusColors = {
    STATUS: styles.blue,
    RESULTS_UPDATED: styles.yellow,
    COMPLETE: styles.green,
    SHARED_CASE: styles.gray,
    FAILED: styles.red,
  };

  const cardColor = statusColors[messageType];

  const notReadStyle = !read ? styles.not_read : '';

  return {
    CardIcon,
    cardColor,
    notReadStyle,
  };
};

export default useNotificationItem;
