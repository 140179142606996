import React from 'react';
import CaseItem from './CaseItem';
import useCaseList from './useCaseList';
import ProjectOrCaseRemoveModal from './ProjectOrCaseRemoveModal';

import styles from './CaseList.module.css';

const CaseList = ({ cases, solverName, solverAlias, isSelepocoProject }) => {
  const {
    isRemoving,
    setIsRemoving,
    currentCase,
    dispatchRemoveCaseAction,
    handleRemoveCase,
  } = useCaseList();

  return (
    <>
      <div className={styles.header_cases}>
        <p />
        <p>Nome</p>
        <p>Descrição</p>
        <p>Criado em</p>
        <p>Status</p>
      </div>
      <div className={styles.caseList}>
        {cases?.length ? (
          cases.map(caseItem => (
            <CaseItem
              key={caseItem.id}
              caseItem={caseItem}
              solverName={solverName}
              solverAlias={solverAlias}
              handleRemoveCase={handleRemoveCase}
              isSelepocoProject={isSelepocoProject}
            />
          ))
        ) : (
          <div className={styles.no_cases}>Não há casos neste projeto.</div>
        )}
      </div>
      {isRemoving && (
        <ProjectOrCaseRemoveModal
          type="case"
          projectOrCaseName={currentCase.name}
          removeHandler={dispatchRemoveCaseAction}
          closeModal={() => setIsRemoving(false)}
        />
      )}
    </>
  );
};

export default CaseList;
