import React from 'react';
import LeftMainPanel from './componentes/LeftMainPanel';
import LeftNavPanel from './componentes/LeftNavPanel';
import WikkiLogoBelow from './componentes/WikkiLogoBelow';
import classes from './leftPanel.module.css';

const LeftSideBar = ({ collapsed, hasLeftNavBar }) => (
  <div
    className={`${classes.left_sidebar} ${
      collapsed ? '' : classes.sidebar_is_open
    }`}
  >
    <div className={classes.left_sidebar_content}>
      <span>
        <LeftMainPanel collapsed={collapsed} />
      </span>

      <span>{hasLeftNavBar && <LeftNavPanel collapsed={collapsed} />}</span>
    </div>

    <span className={classes.wikki_logo_below}>
      <WikkiLogoBelow collapsed={collapsed} />
    </span>
  </div>
);

export default LeftSideBar;
