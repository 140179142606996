/* eslint-disable no-use-before-define */
import { useEffect } from 'react';

const useClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClickOutside(false);
    }
  };
};

export default useClickOutside;
