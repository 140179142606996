import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toggleFavorite as toggleFavoriteAction } from '../../state/categories/categoriesActions';

function useSolverPage() {
  const dispatch = useDispatch();
  const { solverId } = useParams();
  const solver = useSelector(state =>
    state.categories.items.reduce(
      (desiredSolver, category) =>
        desiredSolver ?? category.solvers.find(s => s.id === Number(solverId)),
      null
    )
  );

  function toggleFavorite(id) {
    dispatch(toggleFavoriteAction(id));
  }

  return { solver, toggleFavorite };
}

export default useSolverPage;
