import * as actionTypes from './caseSharingRequestsActionsType';

const initialState = {
  isLoading: false,
  items: [],
  error: null,
};

export default function caseSharingRequestsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_CASE_SHARING_REQUESTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_CASE_SHARING_REQUESTS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        items: action.payload.caseSharingRequests,
        error: null,
      };
    case actionTypes.FETCH_CASE_SHARING_REQUESTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.ACCEPT_CASE_SHARING_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.ACCEPT_CASE_SHARING_REQUEST_SUCCEEDED:
      return {
        isLoading: false,
        items: state.items.filter(
          caseSharingRequest =>
            caseSharingRequest.id !== action.payload.caseSharingRequestId
        ),
        error: null,
      };
    case actionTypes.ACCEPT_CASE_SHARING_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.REJECT_CASE_SHARING_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.REJECT_CASE_SHARING_REQUEST_SUCCEEDED:
      return {
        isLoading: false,
        items: state.items.filter(
          caseSharingRequest =>
            caseSharingRequest.id !== action.payload.caseSharingRequestId
        ),
        error: null,
      };
    case actionTypes.REJECT_CASE_SHARING_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
