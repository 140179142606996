import React from 'react';
import style from './aboutSection.module.css';
import aboutImage from './assets/home_about_section.jpg';

const AboutSectionContent = () => (
  <div id="plataforma" className={style['section-info']}>
    <div className={style.spacer} />
    <div className={style.info}>
      <div className={style['description-box']}>
        <div className={style.header}>
          <h1 className={style.title}>A Plataforma</h1>
          <div className={style.subtitle}>PETROFOAM</div>
        </div>
        <hr />
        <p className={style.description}>
          O PetroFOAM é uma plataforma para integrar simuladores de diferentes
          áreas da engenharia. A base da plataforma foi planejada para
          disponibilizar simuladores que se beneficiam da computação de alto
          desempenho (HPC - High-Performance Computing). Através da comunicação
          com Clusters de processamento fica transparente fazer o balanceamento
          da execução de simulações de forma geral, sejam elas simples e com
          baixa demanda computacional ou simulações complexas e que necessitam o
          uso de processamento distribuído e de alta demanda computacional. Esse
          último cenário ocorre em simuladores para problemas complexos
          envolvendo soluções transientes e tridimensionais (3D), como por
          exemplo: em aplicações que usam CFD (Computational Fluid Dynamics)
          para simular reservatórios complexos ou escoamentos multifásicos e
          reativos em testemunhos, simulação de equipamentos, tubulações,
          válvulas, etc. Apesar da complexidade desse tipo de solução, existe
          uma ideia desafiadora e que norteia os desenvolvimentos entregues na
          plataforma - os simuladores disponibilizados devem ser de simples
          operação e customizados para a aplicação de interesse, facilitando
          assim o uso do mesmo para obtenção de resultados úteis para tomada de
          decisão pelo engenheiro de operação sem a necessidade de conhecimentos
          aprofundados nas metodologias numéricas usadas.
        </p>
      </div>
      <div className={style['image-border']} />
      <img src={aboutImage} alt="Fluido escoando sobre uma superfície plana." />
    </div>
  </div>
);

export default AboutSectionContent;
