import React from 'react';
import { Link } from 'react-router-dom';
import { MdEdit as EditIcon, MdDelete as DeleteIcon } from 'react-icons/md';

import { ReactComponent as NeoCaseIcon } from '../../../assets/icons/neo-case-icon.svg';
import { ReactComponent as ProdPocoCaseIcon } from '../../../assets/icons/prodpoco-case-icon.svg';
import { ReactComponent as KrelCaseIcon } from '../../../assets/icons/krel-case-icon.svg';
import { ReactComponent as KabsCaseIcon } from '../../../assets/icons/kabs-case-icon.svg';

import useCaseItem from './useCaseItem';
import ProjectOrCaseUpdateModal from './ProjectOrCaseUpdateModal';

import styles from './CaseItem.module.css';

const CaseItem = ({
  caseItem,
  solverAlias,
  handleRemoveCase,
  isSelepocoProject,
}) => {
  const {
    values,
    handleChange,
    isEditing,
    setIsEditing,
    handleUpdateCase,
    errors,
    readOnly,
  } = useCaseItem(caseItem);

  const created = new Date(caseItem.created_at);
  return (
    <>
      <Link
        className={styles.cases_row}
        to={`/solver/${solverAlias}/case/${caseItem.id}`}
      >
        {solverAlias === 'neo' && <NeoCaseIcon />}
        {solverAlias === 'prodpoco' && <ProdPocoCaseIcon />}
        {solverAlias === 'krel' && <KrelCaseIcon />}
        {solverAlias === 'kabs' && <KabsCaseIcon />}
        <div>{caseItem.name}</div>
        <div>{caseItem.description || '-'}</div>
        <div>{created.toLocaleString()}</div>
        <div>{caseItem.status}</div>
        {!isSelepocoProject && (
          <button
            type="button"
            className={styles.icon}
            title="Editar"
            onClick={e => {
              e.preventDefault();
              setIsEditing(!isEditing);
            }}
            disabled={readOnly}
          >
            <EditIcon />
          </button>
        )}
        {!isSelepocoProject && (
          <button
            type="button"
            className={styles.icon}
            title="Deletar"
            onClick={e => {
              handleRemoveCase(caseItem);
              e.preventDefault();
            }}
            disabled={readOnly}
          >
            <DeleteIcon />
          </button>
        )}
      </Link>
      {isEditing && (
        <ProjectOrCaseUpdateModal
          type="case"
          values={values}
          handleChange={handleChange}
          handleUpdate={handleUpdateCase}
          errors={errors}
          closeModal={() => setIsEditing(false)}
        />
      )}
    </>
  );
};

export default CaseItem;
