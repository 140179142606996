export const FETCH_SOLVER_FEEDBACK_STARTED = 'FETCH_SOLVER_FEEDBACK_STARTED';
export const FETCH_SOLVER_FEEDBACK_SUCCEEDED =
  'FETCH_SOLVER_FEEDBACK_SUCCEEDED';
export const FETCH_SOLVER_FEEDBACK_FAILED = 'FETCH_SOLVER_FEEDBACK_FAILED';

export const SEND_SOLVER_FEEDBACK_STARTED = 'SEND_SOLVER_FEEDBACK_STARTED';
export const SEND_SOLVER_FEEDBACK_SUCCEEDED = 'SEND_SOLVER_FEEDBACK_SUCCEEDED';
export const SEND_SOLVER_FEEDBACK_FAILED = 'SEND_SOLVER_FEEDBACK_FAILED';

export const REMOVE_SOLVER_FEEDBACK_STARTED = 'REMOVE_SOLVER_FEEDBACK_STARTED';
export const REMOVE_SOLVER_FEEDBACK_SUCCEEDED =
  'REMOVE_SOLVER_FEEDBACK_SUCCEEDED';
export const REMOVE_SOLVER_FEEDBACK_FAILED = 'REMOVE_SOLVER_FEEDBACK_FAILED';

export const LIKE_SOLVER_FEEDBACK_STARTED = 'LIKE_SOLVER_FEEDBACK_STARTED';
export const LIKE_SOLVER_FEEDBACK_SUCCEEDED = 'LIKE_SOLVER_FEEDBACK_SUCCEEDED';
export const LIKE_SOLVER_FEEDBACK_FAILED = 'LIKE_SOLVER_FEEDBACK_FAILED';

export const DISLIKE_SOLVER_FEEDBACK_STARTED =
  'DISLIKE_SOLVER_FEEDBACK_STARTED';
export const DISLIKE_SOLVER_FEEDBACK_SUCCEEDED =
  'DISLIKE_SOLVER_FEEDBACK_SUCCEEDED';
export const DISLIKE_SOLVER_FEEDBACK_FAILED = 'DISLIKE_SOLVER_FEEDBACK_FAILED';
