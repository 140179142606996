import React from 'react';

import NotificationItem from './NotificationItem';
import useNotifications from './useNotifications';

import styles from './Notifications.module.css';

const Notifications = ({ notifications }) => {
  const { markAsRead, handleDeleteNotification } = useNotifications();

  return (
    <div className={styles.notifications}>
      {notifications.map(notification => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          markAsRead={markAsRead}
          handleDeleteNotification={handleDeleteNotification}
        />
      ))}
    </div>
  );
};

export default Notifications;
