import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDiscipline,
  fetchDisciplines,
} from '../../state/disciplines/disciplinesActions';
import {
  fetchUserSettings,
  changeDisciplineUserSettings,
} from '../../state/userSettings/userSettingsActions';

function useDisciplinesPage() {
  const dispatch = useDispatch();
  const { items: disciplines, current: currentDiscipline } = useSelector(
    state => state.disciplines
  );

  const { favoriteDiscipline } = useSelector(state => state.userSettings);

  const [selectedDiscipline, setSelectedDiscipline] =
    useState(currentDiscipline);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, []);

  useEffect(() => {
    if (favoriteDiscipline && Object.keys(favoriteDiscipline).length !== 0)
      setSelectedDiscipline(favoriteDiscipline);
  }, [favoriteDiscipline]);

  useEffect(() => {
    if (Object.keys(disciplines).length === 0 && !selectedDiscipline) {
      dispatch(fetchDisciplines());
    }
  }, [dispatch, disciplines, selectedDiscipline]);

  useEffect(() => {
    if (selectedDiscipline && selectedDiscipline !== currentDiscipline) {
      dispatch(setCurrentDiscipline(selectedDiscipline));
    }
  }, [selectedDiscipline, currentDiscipline, dispatch]);

  function handleChooseDiscipline(discipline) {
    setSelectedDiscipline(previousDiscipline => ({
      ...previousDiscipline,
      ...discipline,
    }));
    dispatch(changeDisciplineUserSettings({ favoriteDiscipline: discipline }));
  }

  return {
    disciplines,
    selectedDiscipline,
    handleChooseDiscipline,
  };
}

export default useDisciplinesPage;
