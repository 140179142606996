import React from 'react';
import classes from './discipline.module.css';

const Discipline = props => {
  const { discipline, handleChooseDiscipline } = props;

  const chooseDiscipline = () => {
    handleChooseDiscipline(discipline);
  };

  return (
    <div
      className={classes.discipline}
      onClick={chooseDiscipline}
      onKeyPress={chooseDiscipline}
      role="button"
      tabIndex="0"
      data-testid="discipline"
    >
      <div className={classes['discipline-header']}>
        <p>{discipline.abbreviation}</p>
      </div>
      <div className={classes['discipline-body']}>
        <p>{discipline.name}</p>
      </div>
    </div>
  );
};

export default Discipline;
