/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  fetchProjectsBySolver,
  closeNewProjectModal,
  openNewProjectModal,
} from '../../state/projects/projectsActions';
import { ReactComponent as NeoCaseIcon } from '../../assets/icons/neo-case-icon.svg';
import { ReactComponent as ProdPocoCaseIcon } from '../../assets/icons/prodpoco-case-icon.svg';
import { ReactComponent as KrelCaseIcon } from '../../assets/icons/krel-case-icon.svg';
import { ReactComponent as KabsCaseIcon } from '../../assets/icons/kabs-case-icon.svg';

const useProjectsManagementPage = solverId => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const { currentProjects, isLoading, isCreatingNewProject } = useSelector(
    state => state.projects
  );
  const { items: categories } = useSelector(state => state.categories);

  const solver = categories
    .find(c => c.solvers.find(s => s.id === Number(solverId)))
    ?.solvers.find(s => s.id === Number(solverId));

  let SolverIcon = '';
  if (solver && solver.name === 'Krel') {
    SolverIcon = KrelCaseIcon;
  } else if (solver) {
    SolverIcon = solver.name === 'Neo' ? NeoCaseIcon : ProdPocoCaseIcon;
  }

  if (solver && solver.name === 'Kabs') {
    SolverIcon = KabsCaseIcon;
  }

  useEffect(() => {
    dispatch(fetchProjectsBySolver(solverId));
  }, [dispatch]);

  useEffect(() => {
    if (isCreatingNewProject)
      document.documentElement.style.overflow = 'hidden';
    else document.documentElement.style.overflow = 'initial';
  }, [isCreatingNewProject]);

  const closeModal = () => {
    dispatch(closeNewProjectModal());
  };
  const openModal = () => {
    dispatch(openNewProjectModal());
  };

  const solverProjectsFiltered = () => {
    const solverProjects = currentProjects.filter(
      project => project.solver.id === Number(solverId)
    );

    if (searchTerm === '') {
      return solverProjects;
    }

    const filterByProjectOrCaseName = searchResults =>
      searchResults.map(project => {
        const { name, cases } = project;
        const matchWithProjectName = name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        if (matchWithProjectName) {
          return project;
        }

        const filteredCasesBySearchTerm = cases?.filter(caseItem =>
          caseItem.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return { ...project, cases: filteredCasesBySearchTerm };
      });

    const filterProjectsWithoutCases = searchResults =>
      searchResults.filter(project => {
        const matchWithProjectName = project.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        if (matchWithProjectName) {
          return project;
        }
        return project.cases?.length;
      });

    let searchResults = filterByProjectOrCaseName(solverProjects);
    searchResults = filterProjectsWithoutCases(searchResults);

    return searchResults;
  };

  return {
    solver,
    solverProjectsFiltered,
    searchTerm,
    setSearchTerm,
    isLoading,
    isCreatingNewProject,
    openModal,
    closeModal,
    SolverIcon,
  };
};

export default useProjectsManagementPage;
