import * as actionTypes from './userSettingsActionTypes';
import * as api from '../../api/userSettingsApi';

export function fetchUserSettingsStarted() {
  return {
    type: actionTypes.FETCH_USER_SETTINGS_STARTED,
  };
}

export function fetchUserSettingsSucceeded({
  favoriteCluster,
  favoriteDiscipline,
  accountName,
}) {
  return {
    type: actionTypes.FETCH_USER_SETTINGS_SUCCEEDED,
    payload: {
      favoriteCluster,
      favoriteDiscipline,
      accountName,
    },
  };
}

export function fetchUserSettingsFailed(error) {
  return {
    type: actionTypes.FETCH_USER_SETTINGS_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchUserSettings() {
  return async (dispatch, getState) => {
    dispatch(fetchUserSettingsStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.readUserSettings({ token: user.token });

      const userSettings = {
        favoriteCluster: data.favorite_cluster,
        favoriteDiscipline: data.favorite_discipline,
        accountName: data.account_name,
      };

      dispatch(fetchUserSettingsSucceeded(userSettings));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchUserSettingsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchUserSettingsFailed(error.detail));
      } else {
        dispatch(fetchUserSettingsFailed(error));
      }
    }
  };
}

export function editUserSettingsStarted() {
  return {
    type: actionTypes.EDIT_USER_SETTINGS_STARTED,
  };
}

export function editUserSettingsSucceeded({
  favoriteCluster,
  favoriteDiscipline,
  accountName,
}) {
  return {
    type: actionTypes.EDIT_USER_SETTINGS_SUCCEEDED,
    payload: {
      favoriteCluster,
      favoriteDiscipline,
      accountName,
    },
  };
}

export function editUserSettingsFailed(error) {
  return {
    type: actionTypes.EDIT_USER_SETTINGS_FAILED,
    payload: {
      error,
    },
  };
}

export function editUserSettings(newSettings) {
  return async (dispatch, getState) => {
    dispatch(editUserSettingsStarted());

    const {
      auth: { user },
      clusters: { items: clustersItems },
      disciplines: { items: disciplinesItems },
    } = getState();

    let nameAccount = null;
    if (newSettings.favoriteCluster.has_account_control) {
      nameAccount = newSettings.accountName;
    }

    try {
      const body = {
        favorite_discipline: newSettings.favoriteDiscipline?.id,
        favorite_cluster: newSettings.favoriteCluster.id,
        account_name: nameAccount,
      };

      const data = await api.updateUserSettings({ token: user.token }, body);

      const settingsData = {
        favoriteCluster: clustersItems.find(
          cluster => cluster.id === data.favorite_cluster
        ),
        favoriteDiscipline: disciplinesItems.find(
          discipline => discipline.id === data.favorite_discipline
        ),
        accountName: data.account_name,
      };

      dispatch(editUserSettingsSucceeded(settingsData));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(editUserSettingsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(editUserSettingsFailed(error.detail));
      } else {
        dispatch(editUserSettingsFailed(error.message));
      }
    }
  };
}

export function createUserSettingsStarted() {
  return {
    type: actionTypes.CREATE_USER_SETTINGS_STARTED,
  };
}

export function createUserSettingsSucceeded({
  favoriteCluster,
  favoriteDiscipline,
  accountName,
}) {
  return {
    type: actionTypes.CREATE_USER_SETTINGS_SUCCEEDED,
    payload: {
      favoriteCluster,
      favoriteDiscipline,
      accountName,
    },
  };
}

export function createUserSettingsFailed(error) {
  return {
    type: actionTypes.CREATE_USER_SETTINGS_FAILED,
    payload: {
      error,
    },
  };
}

export function createUserSettings(newSettings) {
  return async (dispatch, getState) => {
    dispatch(createUserSettingsStarted());

    const {
      auth: { user },
      clusters: { items: clustersItems },
      disciplines: { items: disciplinesItems },
      account_name: accountName,
    } = getState();

    try {
      const body = {
        favorite_discipline: newSettings.favoriteDiscipline.id,
        favorite_cluster: newSettings.favoriteCluster.id,
        account_name: newSettings.accountName,
      };

      const data = await api.createUserSettings({ token: user.token }, body);

      const settingsData = {
        favoriteCluster: clustersItems.find(
          cluster => cluster.id === data.favorite_cluster
        ),
        favoriteDiscipline: disciplinesItems.find(
          discipline => discipline.id === data.favorite_discipline
        ),
        accountName: { accountName },
      };

      dispatch(createUserSettingsSucceeded(settingsData));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(createUserSettingsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(createUserSettingsFailed(error.detail));
      } else {
        dispatch(createUserSettingsFailed(error.message));
      }
    }
  };
}

export function changeDisciplineUserSettings(newDiscipline) {
  return async (dispatch, getState) => {
    dispatch(changeDisciplineUserSettingsStarted());

    const {
      auth: { user },
      disciplines: { items: disciplinesItems },
    } = getState();

    try {
      const body = {
        favorite_discipline: newDiscipline.favoriteDiscipline?.id,
      };

      const data = await api.updateUserSettings({ token: user.token }, body);

      const settingsData = {
        favoriteDiscipline: disciplinesItems.find(
          discipline => discipline.id === data.favorite_discipline
        ),
      };

      dispatch(editUserSettingsSucceeded(settingsData));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(
          changeDisciplineUserSettingsFailed('Token is invalid or expired')
        );
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(changeDisciplineUserSettingsFailed(error.detail));
      } else {
        dispatch(changeDisciplineUserSettingsFailed(error.message));
      }
    }
  };
}

export function changeDisciplineUserSettingsStarted() {
  return {
    type: actionTypes.CHANGE_DISCIPLINE_USER_SETTINGS_STARTED,
  };
}

export function changeDisciplineUserSettingsSucceeded({ favoriteDiscipline }) {
  return {
    type: actionTypes.CHANGE_DISCIPLINE_USER_SETTINGS_SUCCEEDED,
    payload: {
      favoriteDiscipline,
    },
  };
}

export function changeDisciplineUserSettingsFailed(error) {
  return {
    type: actionTypes.CHANGE_DISCIPLINE_USER_SETTINGS_FAILED,
    payload: {
      error,
    },
  };
}
