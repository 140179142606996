import React, { useState, cloneElement } from 'react';
import styles from './HeaderItem.module.css';

const HeaderItem = ({ children, icon }) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={styles.header_item}>
      <button
        type="button"
        className={styles.header_item_button}
        onClick={() => setOpen(!open)}
      >
        {icon}
      </button>
      {open && cloneElement(children, { onClickOutside: setOpen })}
    </li>
  );
};

export default HeaderItem;
