import { lazy } from 'react';

import store from '../state/store';
import staticReducers from '../state/staticReducers';

const SolverNeoPage = lazy(async () => {
  const module = await import('../pages/SolverNeoPage/SolverNeoPage');
  const { default: neoReducer } = await import(
    '../solvers/neo/reducers/neoReducer'
  );

  store.resetReducers(staticReducers);
  store.injectReducer('neo', neoReducer);
  return module;
});

const SolverProdpocoPage = lazy(async () => {
  const module = await import('../pages/SolverProdpocoPage/SolverProdpocoPage');
  const { default: prodpocoReducer } = await import(
    '../solvers/prodpoco/reducers/prodpocoReducer'
  );

  store.resetReducers(staticReducers);
  store.injectReducer('prodpoco', prodpocoReducer);
  return module;
});

const SolverProdpocoRunMultiplesCases = lazy(async () => {
  const module = await import(
    '../solvers/prodpoco/containers/RunMultiplesCases'
  );
  const { default: prodpocoReducer } = await import(
    '../solvers/prodpoco/reducers/prodpocoReducer'
  );

  store.resetReducers(staticReducers);
  store.injectReducer('prodpoco', prodpocoReducer);
  return module;
});

const AccumulatedVolumeComparation = lazy(async () => {
  const module = await import(
    '../solvers/prodpoco/containers/AccumulatedVolume'
  );
  const { default: prodpocoReducer } = await import(
    '../solvers/prodpoco/reducers/prodpocoReducer'
  );

  store.resetReducers(staticReducers);
  store.injectReducer('prodpoco', prodpocoReducer);
  return module;
});

const SolverProdpocoFromSelepoco = lazy(async () => {
  const module = await import('../solvers/prodpoco/pages/ImportFromSelepoco');
  const { default: selepocoReducer } = await import(
    '../solvers/prodpoco/reducers/selepocoReducer'
  );

  store.resetReducers(staticReducers);
  store.injectReducer('selepoco', selepocoReducer);
  return module;
});

const SolverKrel = lazy(async () => {
  const module = await import('../solvers/krel');
  const { default: krelReducer } = await import(
    '../solvers/krel/reducers/krelReducer'
  );

  store.resetReducers(staticReducers);
  store.injectReducer('krel', krelReducer);
  return module;
});

const SolverKabs = lazy(async () => {
  const module = await import('../solvers/kabs');
  const { default: kabsReducer } = await import('../solvers/kabs/store');

  store.resetReducers(staticReducers);
  store.injectReducer('kabs', kabsReducer);

  return module;
});

export {
  SolverNeoPage,
  SolverProdpocoPage,
  SolverProdpocoRunMultiplesCases,
  AccumulatedVolumeComparation,
  SolverProdpocoFromSelepoco,
  SolverKrel,
  SolverKabs,
};
