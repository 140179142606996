/* eslint-disable react/jsx-wrap-multilines */
import { Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';

import Layout from '../Layout/index';
import LoadingBar from '../components/LoadingBar/LoadingBar';
import {
  SolverNeoPage,
  SolverProdpocoPage,
  SolverProdpocoRunMultiplesCases,
  AccumulatedVolumeComparation,
  SolverProdpocoFromSelepoco,
  SolverKrel,
  SolverKabs,
} from './solversLoad';
import RequireAuth from './RequireAuth';

import {
  DisciplinesPage,
  CategoriesPage,
  ContactPage,
  FavoritesPage,
  ProjectsPage,
  CaseSharingRequestsPage,
  UserSettingsPage,
  ProjectsManagementPage,
  SolverPage,
} from '../pages';

const UserRoutes = () => (
  <Layout>
    <Suspense fallback={<LoadingBar />}>
      <Routes>
        <Route
          path="/disciplines"
          element={
            <RequireAuth>
              <DisciplinesPage />
            </RequireAuth>
          }
        />
        <Route
          path="/categories"
          element={
            <RequireAuth>
              <CategoriesPage />
            </RequireAuth>
          }
        />
        <Route
          path="/contact"
          element={
            <RequireAuth>
              <ContactPage />
            </RequireAuth>
          }
        />
        <Route
          path="/favorites"
          element={
            <RequireAuth>
              <FavoritesPage />
            </RequireAuth>
          }
        />
        <Route
          path="/projects"
          element={
            <RequireAuth>
              <ProjectsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/prodpoco/cases/:projectId"
          element={
            <RequireAuth>
              <SolverProdpocoRunMultiplesCases />
            </RequireAuth>
          }
        />
        <Route
          path="/prodpoco/cases/:projectId/accumulated-volume/comparison"
          element={
            <RequireAuth>
              <AccumulatedVolumeComparation />
            </RequireAuth>
          }
        />
        <Route
          path="/prodpoco/selepoco"
          element={
            <RequireAuth>
              <SolverProdpocoFromSelepoco />
            </RequireAuth>
          }
        />
        <Route
          path="/shares"
          element={
            <RequireAuth>
              <CaseSharingRequestsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <UserSettingsPage />
            </RequireAuth>
          }
        />
        <Route
          path="/solvers/:id/projects"
          element={
            <RequireAuth>
              <ProjectsManagementPage />
            </RequireAuth>
          }
        />
        <Route
          path="/solvers/:solverId"
          element={
            <RequireAuth>
              <SolverPage />
            </RequireAuth>
          }
        />
        <Route path="/solver">
          <Route
            path="neo/case/:caseId"
            element={
              <RequireAuth>
                <SolverNeoPage />
              </RequireAuth>
            }
          />
          <Route
            path="prodpoco/case/:caseId"
            element={
              <RequireAuth>
                <SolverProdpocoPage />
              </RequireAuth>
            }
          />
          <Route
            path="krel/case/:caseId"
            element={
              <RequireAuth>
                <SolverKrel />
              </RequireAuth>
            }
          />

          <Route
            path="kabs/case/:caseId"
            element={
              <RequireAuth>
                <SolverKabs />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  </Layout>
);

export default UserRoutes;
