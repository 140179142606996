import { read } from '../common/services/apiHandler';

async function readClusters(authorization) {
  const response = await read({
    auth: authorization,
    resource: '/clusters/',
  });

  return response;
}

export { readClusters };
