import axios from 'axios';
import store from '../../state/store';
import refreshToken from './refreshToken';

export default axios.create({
  baseURL: window.REACT_APP_PETROFOAM_API_URL,
});

const axiosPrivate = axios.create({
  baseURL: window.REACT_APP_PETROFOAM_API_URL,
  headers: { 'Content-Type': 'application/json' },
});

axiosPrivate.interceptors.request.use(
  config => {
    if (!config.headers['Authorization']) {
      const token = store.getState()?.auth?.user?.token;
      config.headers['Authorization'] = `JWT ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  response => response,
  async error => {
    const previousRequest = error?.config;
    const statusCode = error?.response?.status;
    if ((statusCode === 401 || statusCode === 403) && !previousRequest?.sent) {
      previousRequest.sent = true;
      const newAccessToken = await refreshToken();
      previousRequest.headers['Authorization'] = `JWT ${newAccessToken}`;
      return axiosPrivate(previousRequest);
    }
    return Promise.reject(error);
  }
);

export { axiosPrivate };
