import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../state/solverFeedback/solverFeedbackActions';

export default function useFeedbackSection(solverId) {
  const dispatch = useDispatch();

  const { items: solverFeedbacks } = useSelector(state => state.solverFeedback);
  const { userId } = useSelector(state => state.auth.user);

  const [rating, setRating] = useState(5);
  const [feedbackType, setFeedbackType] = useState('A');
  const [opinion, setOpinion] = useState('');
  const [tab, setTab] = useState('all');

  useEffect(() => {
    dispatch(actions.fetchSolverFeedbacks(solverId));
  }, [dispatch, solverId]);

  const filteredSolverFeedbacks = useMemo(() => {
    if (tab === 'suggestions') {
      return solverFeedbacks.filter(
        solverFeedback => solverFeedback.subject === 'Sugestão'
      );
    }
    if (tab === 'comments') {
      return solverFeedbacks.filter(
        solverFeedback => solverFeedback.subject === 'Comentário'
      );
    }

    return solverFeedbacks.filter(
      solverFeedback => solverFeedback.subject !== 'Avaliação'
    );
  }, [tab, solverFeedbacks]);

  const sendSolverFeedback = () => {
    let title;
    if (feedbackType === 'C') {
      title = 'Comentário';
    } else if (feedbackType === 'S') {
      title = 'Sugestão';
    } else {
      title = 'Avaliação';
    }

    dispatch(
      actions.sendSolverFeedback(solverId, {
        type: feedbackType,
        title,
        text: opinion,
        rating,
      })
    );

    setOpinion('');
  };

  const removeSolverFeedback = id => {
    dispatch(actions.removeSolverFeedback(id));
  };

  const likeSolverFeedback = id => {
    dispatch(actions.likeSolverFeedback(id));
  };

  const dislikeSolverFeedback = id => {
    dispatch(actions.dislikeSolverFeedback(id));
  };

  return {
    userId,
    rating,
    setRating,
    feedbackType,
    setFeedbackType,
    opinion,
    setOpinion,
    tab,
    setTab,
    filteredSolverFeedbacks,
    likeSolverFeedback,
    dislikeSolverFeedback,
    removeSolverFeedback,
    sendSolverFeedback,
  };
}
