import {
  read,
  remove,
  partialUpdate,
  create,
} from '../common/services/apiHandler';

async function readCase(authorization, caseId) {
  const response = await read({
    auth: authorization,
    resource: `/cases/${caseId}/`,
  });

  return response;
}

async function deleteCase(authorization, caseId) {
  const response = await remove({
    auth: authorization,
    resource: `/cases/${caseId}/`,
  });

  return response;
}

async function patchCase(authorization, caseId, body) {
  const response = await partialUpdate({
    auth: authorization,
    resource: `/cases/${caseId}/`,
    body,
  });

  return response;
}

async function createCase(authorization, projectId, body) {
  const response = await create({
    auth: authorization,
    resource: `/projects/${projectId}/cases/`,
    body,
  });

  return response;
}

export { readCase, deleteCase, patchCase, createCase };
