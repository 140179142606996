import React, { useEffect, useRef, useState } from 'react';
import {
  MdClose as CloseIcon,
  MdChevronLeft as ChevronLeftIcon,
  MdChevronRight as ChevronRightIcon,
} from 'react-icons/md';
import SolverCard from './SolverCard';
import classes from './category.module.css';
import SolverFeatured from '../SolverFeatured';

const Category = ({ category, toggleFavoriteState }) => {
  const { name, solvers } = category;
  const [expandedSolver, setExpandedSolver] = useState(null);
  const banner = useRef(null);
  const carousel = useRef();

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (banner.current) {
      banner.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [expandedSolver]);

  return (
    <>
      <div className={classes.name}>{name}</div>
      <div
        ref={carousel}
        className={classes.carousel}
        onScroll={event => setScrollPosition(event.target.scrollLeft)}
      >
        <div className={classes.container}>
          <button
            type="button"
            style={{ visibility: scrollPosition > 0 ? 'visible' : 'hidden' }}
            className={classes.left}
            onClick={() => {
              carousel.current?.scroll({
                left: carousel.current?.scrollLeft - 370,
                behavior: 'smooth',
              });
            }}
          >
            <ChevronLeftIcon />
          </button>
          {solvers.map(solver => (
            <SolverCard
              solver={solver}
              key={`${solver.id}_solver_card`}
              onExpand={() => setExpandedSolver(solver.id)}
              isExpanded={expandedSolver === solver.id}
            />
          ))}
          <button
            type="button"
            style={{
              visibility:
                scrollPosition <
                carousel.current?.scrollWidth - carousel.current?.clientWidth
                  ? 'visible'
                  : 'hidden',
            }}
            className={classes.right}
            onClick={() => {
              carousel.current?.scroll({
                left: carousel.current?.scrollLeft + 370,
                behavior: 'smooth',
              });
            }}
          >
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      {expandedSolver && (
        <div ref={banner} className={classes.banner}>
          <SolverFeatured
            onFavorite={toggleFavoriteState}
            solver={solvers.find(solver => solver.id === expandedSolver)}
          />
          <button
            className={classes['close-button']}
            onClick={() => setExpandedSolver(null)}
            type="button"
          >
            <CloseIcon />
          </button>
        </div>
      )}
    </>
  );
};

export default Category;
