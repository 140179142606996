/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';

import neoIcon from '../../assets/icons/neo-case-icon.svg';
import prodIcon from '../../assets/icons/prodpoco-case-icon.svg';
import krelIcon from '../../assets/icons/krel-case-icon.svg';
import kabsIcon from '../../assets/icons/kabs-case-icon.svg';

import useSimulationItem from './useSimulationItem';

import styles from './SimulationItem.module.css';

const SimulationItem = ({
  simulation,
  deleteSimulationCard,
  handleStopSimulation,
}) => {
  const {
    caseId,
    caseName,
    jobStatus,
    simulationPercentage,
    solverIdentifier,
  } = simulation;
  const { ChangeStatusIcon, cardColor } = useSimulationItem(jobStatus);

  // TODO: É necessário refatorar, solverIndetifier deverá ser normalizado
  let solverRoute = '';
  solverRoute =
    solverIdentifier === 'Kabs'
      ? 'kabs'
      : solverIdentifier === 'Krel'
      ? 'krel'
      : solverIdentifier === 'PlugAcidification'
      ? 'neo'
      : 'prodpoco';

  return (
    <Link
      to={`/solver/${solverRoute}/case/${caseId}`}
      key={caseId}
      className={`${styles.simulation_item} ${cardColor}`}
    >
      <div className={styles.simulation_info}>
        {/* TODO: É necessário refatorar, solverIndetifier deverá ser normalizado */}
        <img
          src={
            solverIdentifier === 'Kabs'
              ? kabsIcon
              : solverIdentifier === 'Krel'
              ? krelIcon
              : solverIdentifier === 'PlugAcidification'
              ? neoIcon
              : prodIcon
          }
          alt={solverIdentifier}
        />
        <p>{caseName}</p>
        <button
          hidden
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (
              jobStatus === 'NA FILA' ||
              jobStatus === 'NA FILA PARA PRÉ-PROCESSAR' ||
              jobStatus === 'EM PRÉ-PROCESSAMENTO' ||
              jobStatus === 'SIMULANDO'
            ) {
              handleStopSimulation(caseId);
            } else {
              deleteSimulationCard(caseId);
            }
          }}
        >
          <ChangeStatusIcon />
        </button>
      </div>
      <div className={styles.simulation_status}>
        <p>{jobStatus}</p>
        {jobStatus === 'SIMULANDO' && (
          <p className={styles.simulation_percentage}>
            {`${simulationPercentage}%`}
          </p>
        )}
      </div>
    </Link>
  );
};

export default SimulationItem;
