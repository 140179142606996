import { create } from '../common/services/apiHandler';

async function contact(
  authorization,
  { name: sender, email, subject, message }
) {
  const response = await create({
    resource: '/mailer/',
    auth: authorization,
    body: { sender, email, subject, message },
  });

  return response;
}

export { contact };
