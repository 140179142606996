import * as actionTypes from './authActionTypes';

function getInitialState() {
  return {
    isLoading: false,
    user: null,
    error: null,
  };
}

export default function authReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.SIGNIN_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.SIGNIN_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        error: null,
      };
    case actionTypes.SIGNIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.SIGNOUT_STARTED:
      return {
        ...state,
        user: null,
      };
    case actionTypes.ACCESS_TOKEN_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload.newAccessToken,
        },
      };
    case 'SIGNIN_AFTER_REFRESH_PAGE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
      };
    default:
      return state;
  }
}
