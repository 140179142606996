import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RequireAuth({ children }) {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();

  if (user && user.username !== 'admin') {
    return children;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
}

export default RequireAuth;
