const RemoveProjectModal = ({ isOpen, closeModal }) => (
  <>
    {isOpen && (
      <div
        className="absolute flex items-center justify-center top-0 left-0 w-full h-full z-50"
        style={{ backgroundColor: '#0000007f' }}
      >
        <div
          className="relative flex flex-col justify-center items-center min-w-fit max-w-2xl w-2/5 h-1/3 max-h-96"
          style={{ backgroundColor: '#223131' }}
        >
          <h3 className="m-8 mb-12 text-3xl">
            Não foi possível excluir o projeto, pois possui casos em execução.
          </h3>

          <button
            title="Fechar janela de alerta"
            className="px-12 py-4 rounded-2xl border border-white"
            type="button"
            onClick={closeModal}
          >
            ok!
          </button>
        </div>
      </div>
    )}
  </>
);

export default RemoveProjectModal;
