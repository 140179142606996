import React from 'react';
import Category from './components/Category';

const Categories = ({ categories, toggleFavoriteState }) => (
  <div>
    {categories.items.map(
      category =>
        category.solvers.length > 0 && (
          <Category
            key={category.id}
            category={category}
            toggleFavoriteState={toggleFavoriteState}
          />
        )
    )}
  </div>
);

export default Categories;
