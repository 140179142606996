export const FETCH_USER_SETTINGS_STARTED = 'FETCH_USER_SETTINGS_STARTED';
export const FETCH_USER_SETTINGS_SUCCEEDED = 'FETCH_USER_SETTINGS_SUCCEEDED';
export const FETCH_USER_SETTINGS_FAILED = 'FETCH_USER_SETTINGS_FAILED';

export const EDIT_USER_SETTINGS_STARTED = 'EDIT_USER_SETTINGS_STARTED';
export const EDIT_USER_SETTINGS_SUCCEEDED = 'EDIT_USER_SETTINGS_SUCCEEDED';
export const EDIT_USER_SETTINGS_FAILED = 'EDIT_USER_SETTINGS_FAILED';

export const CREATE_USER_SETTINGS_STARTED = 'CREATE_USER_SETTINGS_STARTED';
export const CREATE_USER_SETTINGS_SUCCEEDED = 'CREATE_USER_SETTINGS_SUCCEEDED';
export const CREATE_USER_SETTINGS_FAILED = 'CREATE_USER_SETTINGS_FAILED';

export const CHANGE_DISCIPLINE_USER_SETTINGS_STARTED =
  'CHANGE_DISCIPLINE_USER_SETTINGS_STARTED';
export const CHANGE_DISCIPLINE_USER_SETTINGS_SUCCEEDED =
  'CHANGE_DISCIPLINE_USER_SETTINGS_SUCCEEDED';
export const CHANGE_DISCIPLINE_USER_SETTINGS_FAILED =
  'CHANGE_DISCIPLINE_USER_SETTINGS_FAILED';
