/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDisciplines,
  setCurrentDiscipline,
} from '../../state/disciplines/disciplinesActions';
import { fetchClusters } from '../../state/clusters/clustersActions';
import {
  editUserSettings,
  fetchUserSettings,
} from '../../state/userSettings/userSettingsActions';

const useUserSettingsPage = () => {
  const dispatch = useDispatch();

  const { items: disciplines } = useSelector(state => state.disciplines);
  const { items: clusters } = useSelector(state => state.clusters);
  const { favoriteCluster, favoriteDiscipline, accountName, error, isLoading } =
    useSelector(state => state.userSettings);

  const [selectedDiscipline, setSelectedDiscipline] =
    useState(favoriteDiscipline);
  const [selectedCluster, setSelectedCluster] = useState(favoriteCluster);
  const [nameAccount, setNameAccount] = useState(accountName);

  useEffect(() => {
    if (disciplines.length === 0) dispatch(fetchDisciplines());
    if (clusters.length === 0) dispatch(fetchClusters());
    if (
      !favoriteCluster ||
      !favoriteDiscipline ||
      Object.keys(favoriteCluster).length === 0 ||
      Object.keys(favoriteDiscipline).length === 0
    ) {
      dispatch(fetchUserSettings());
    }
  }, [dispatch]);

  useEffect(() => {
    setSelectedCluster(favoriteCluster);
    setSelectedDiscipline(favoriteDiscipline);
  }, [favoriteCluster, favoriteDiscipline]);

  const changeSettings = ({ target: { name, value } }) => {
    if (name === 'nameAccount') {
      setNameAccount(value);
    } else if (name === 'discipline') {
      setSelectedDiscipline(
        disciplines.find(discipline => discipline.id === Number(value))
      );
    } else {
      setSelectedCluster(
        clusters.find(cluster => cluster.id === Number(value))
      );
    }
  };

  const saveSettings = e => {
    e.preventDefault();

    dispatch(setCurrentDiscipline(selectedDiscipline));

    const body = {
      favoriteDiscipline: selectedDiscipline,
      favoriteCluster: selectedCluster,
      accountName: nameAccount,
    };

    dispatch(editUserSettings(body));
  };

  return {
    disciplines,
    selectedDiscipline,
    clusters,
    selectedCluster,
    setNameAccount,
    changeSettings,
    saveSettings,
    favoriteCluster,
    favoriteDiscipline,
    error,
    isLoading,
  };
};

export default useUserSettingsPage;
