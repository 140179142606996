import {
  create,
  partialUpdate,
  read,
  remove,
} from '../common/services/apiHandler';

async function fetchProjects(authorization, solverId) {
  const params = { fields: 'id,name,description,solver,solver__name' };

  const response = await read({
    auth: authorization,
    resource: `/solvers/${solverId}/projects/`,
    params,
  });

  return response;
}

async function fetchSingleProject(authorization, projectId) {
  const params = { fields: 'id,name,description' };
  const response = await read({
    auth: authorization,
    resource: `/projects/${projectId}/`,
    params,
  });

  return response;
}

async function fetchAllProjectsWithCases(authorization) {
  const params = { order_by: 'created_at' };

  const response = await read({
    auth: authorization,
    resource: `/projects/cases/`,
    params,
  });

  return response;
}

async function fetchProjectsBySolver(authorization, solverId) {
  const params = {
    fields: 'id,name,description,solver,solver__name,created_at,cases',
  };

  const response = await read({
    auth: authorization,
    resource: `/solvers/${solverId}/projects/`,
    params,
  });

  return response;
}

async function fetchProjectsByDiscipline(authorization, disciplineId) {
  const response = await read({
    auth: authorization,
    resource: `/disciplines/${disciplineId}/projects/`,
  });

  return response;
}

async function createProject(authorization, solverId, body) {
  const response = await create({
    auth: authorization,
    resource: `/solvers/${solverId}/projects/`,
    body,
  });

  return response;
}

async function createSelepoco(authorization, body) {
  const response = await create({
    auth: authorization,
    resource: `/import/well_reservoir/`,
    body,
  });

  return response;
}

async function deleteProject(authorization, projectId) {
  const response = await remove({
    auth: authorization,
    resource: `/projects/${projectId}/`,
  });

  return response;
}

async function patchProject(authorization, projectId, body) {
  const response = await partialUpdate({
    auth: authorization,
    resource: `/projects/${projectId}/`,
    body,
  });

  return response;
}

async function exportSelepoco(authorization, body) {
  const response = await create({
    auth: authorization,
    resource: '/selepoco/export/',
    body,
  });

  return response;
}

export {
  fetchProjects,
  fetchSingleProject,
  fetchAllProjectsWithCases,
  fetchProjectsBySolver,
  fetchProjectsByDiscipline,
  createProject,
  createSelepoco,
  deleteProject,
  patchProject,
  exportSelepoco,
};
