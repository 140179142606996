import React from 'react';
import SimulationItem from './SimulationItem';
import useSimulations from './useSimulations';
import SearchField from '../../components/SearchField';

import styles from './Simulations.module.css';

const Simulations = ({ simulations }) => {
  const {
    deleteSimulationCard,
    handleStopSimulation,
    sortedAndFilteredSimulations,
    searchTerm,
    setSearchTerm,
  } = useSimulations(simulations);

  return (
    <div className={styles.container}>
      <div className={styles.search_container}>
        <SearchField
          full
          placeholder="Nome do caso"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div className={styles.simulations}>
        {sortedAndFilteredSimulations.map(simulation => (
          <SimulationItem
            key={simulation.caseId}
            simulation={simulation}
            deleteSimulationCard={deleteSimulationCard}
            handleStopSimulation={handleStopSimulation}
          />
        ))}
      </div>
    </div>
  );
};

export default Simulations;
