import { partialUpdate, remove } from '../common/services/apiHandler';

async function markNotificationsAsRead(authorization, notifications) {
  const response = await partialUpdate({
    auth: authorization,
    resource: '/notification/',
    body: {
      notifications,
    },
  });

  return response;
}

async function removeNotifications(authorization, notifications) {
  const response = await remove({
    auth: authorization,
    resource: '/notification/',
    body: {
      notifications,
    },
  });

  return response;
}

export { markNotificationsAsRead, removeNotifications };
