import React from 'react';
import { Categories } from '../categories';
import useMyFavorites from './useMyFavorites';

const MyFavorites = () => {
  const { favorites, toggleFavoriteState } = useMyFavorites();

  return (
    <div className="favorites">
      <Categories
        categories={{ items: favorites }}
        toggleFavoriteState={toggleFavoriteState}
      />
    </div>
  );
};

export default MyFavorites;
