import { read, partialUpdate, create } from '../common/services/apiHandler';

async function readUserSettings(authorization) {
  const response = await read({
    auth: authorization,
    resource: '/user_preferences/',
  });

  return response;
}

async function updateUserSettings(authorization, body) {
  const response = await partialUpdate({
    auth: authorization,
    body,
    resource: '/user_preferences/',
  });

  return response;
}

async function createUserSettings(authorization, body) {
  const response = await create({
    auth: authorization,
    body,
    resource: '/user_preferences/',
  });

  return response;
}

export { readUserSettings, updateUserSettings, createUserSettings };
