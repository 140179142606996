import React from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';

import styles from './NewCaseModal.module.css';
import useNewCaseModal from './useNewCaseModal';

import Loading from '../../../components/LoadingBar/LoadingBar';
import { AlertMessage } from '../../../components/AlertMessage';

const NewCaseModal = ({ closeModal, projectId, projectName }) => {
  const {
    inputData,
    handleChange,
    handleCreate,
    inputDataErrors,
    projectsError,
    isLoading,
  } = useNewCaseModal(projectId);

  const { name, description } = inputData;
  const displayName =
    projectName.length > 40
      ? `...${projectName.slice(projectName.length - 40)}`
      : projectName;

  return (
    <div className={styles.modal} data-testid="outsideModal">
      <div className={styles.edit_modal}>
        {isLoading && <Loading />}
        <div className={styles.form_modal}>
          {projectsError && (
            <AlertMessage
              content={projectsError}
              contextualClass="danger"
              dismissible
            />
          )}
          <form onSubmit={handleCreate}>
            <button
              type="button"
              className={styles.close_icon}
              onClick={closeModal}
            >
              <CloseIcon />
            </button>

            <div className={styles.edit_header}>
              <h1>Criar caso</h1>
              <p>Novo caso para o projeto:</p>
              <p>
                <span title={projectName}>{displayName}</span>
              </p>
            </div>
            <p className={styles.inputLabel}>Nome do caso</p>
            <input
              className={
                inputDataErrors.name
                  ? `${styles.text_input} ${styles.text_input_error}`
                  : styles.text_input
              }
              type="text"
              value={name}
              name="name"
              onChange={handleChange}
            />
            <p className={styles.input_label}>Descrição do caso</p>
            <textarea
              className={styles.text_input}
              value={description}
              name="description"
              onChange={handleChange}
            />
            {Object.keys(inputDataErrors).map(key => (
              <p key={key} className={styles.update_errors}>
                {inputDataErrors[key]}
              </p>
            ))}
            <div className={styles.buttons_wrapper}>
              <button
                type="submit"
                disabled={Object.keys(inputDataErrors).length > 0}
              >
                Confirmar
              </button>
              <button type="button" onClick={closeModal}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewCaseModal;
