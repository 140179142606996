import { remove } from '../common/services/apiHandler';

async function removeSimulation(authorization, simulationId) {
  const response = await remove({
    auth: authorization,
    resource: `/simulations/${simulationId}/`,
  });

  return response;
}

export { removeSimulation };
