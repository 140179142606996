import { React, useState } from 'react';
import { MdWarning as WarningIcon } from 'react-icons/md';

import styles from './ProjectOrCaseRemoveModal.module.css';

const ProjectOrCaseRemoveModal = ({
  type,
  projectOrCaseName,
  removeHandler,
  closeModal,
}) => {
  const projectMessage =
    'Tem certeza que deseja apagar este Projeto? Todas as suas informações, casos e seus resultados serão apagados para sempre.';
  const caseMessage =
    'Tem certeza que deseja apagar este Caso? Todo seu setup e resultados serão apagados para sempre.';

  const entityInfo =
    type === 'project'
      ? { name: 'Projeto', message: projectMessage }
      : { name: 'Caso', message: caseMessage };

  const [isValid, setIsValid] = useState(false);

  const checkIfInputEqualsName = inputValue => {
    const isInputValid =
      inputValue.slice(0, 10) === projectOrCaseName.slice(0, 10);

    setIsValid(isInputValid);
  };

  return (
    <div
      className={styles.modal}
      onClick={closeModal}
      aria-hidden
      data-testid="outsideModal"
    >
      <div
        className={styles.remove_modal}
        onClick={e => e.stopPropagation()}
        aria-hidden
      >
        <div className={styles.remove_header}>
          <WarningIcon />
          <div className={styles.remove_heading}>
            <h1>{`Apagar ${entityInfo.name}`}</h1>
            <p>{entityInfo.message}</p>
            <p>
              {`Por favor digite `}
              <strong>{projectOrCaseName}</strong>
              {` para confirmar.`}
            </p>
            <input
              type="text"
              autoComplete="off"
              onChange={e => {
                const { value } = e.target;
                checkIfInputEqualsName(value);
              }}
            />
          </div>
        </div>
        <div className={styles.delete_buttons}>
          <button type="submit" disabled={!isValid} onClick={removeHandler}>
            Apagar
          </button>
          <button type="button" onClick={closeModal}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectOrCaseRemoveModal;
