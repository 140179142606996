import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  switchCurrentTab,
  toggleRightPanel,
  deleteNotification,
} from '../state/ui/rightPanel/rightPanelActions';

const useRightPanel = () => {
  const dispatch = useDispatch();

  const { notifications, simulations } = useSelector(
    state => state.ui.rightPanel
  );

  const [openModal, setOpenModal] = useState(false);

  const removeHandler = e => {
    e.preventDefault();

    const allNotificationsIds = notifications.items.map(
      notification => notification.id
    );

    dispatch(deleteNotification(allNotificationsIds));
    setOpenModal(false);
  };

  const switchTab = tab => {
    dispatch(switchCurrentTab(tab));
  };

  const togglePanel = () => {
    dispatch(toggleRightPanel());
  };

  return {
    notifications,
    simulations,
    removeHandler,
    openModal,
    setOpenModal,
    switchTab,
    togglePanel,
  };
};

export default useRightPanel;
