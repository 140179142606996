import authReducer from './auth/authReducer';
import disciplinesReducer from './disciplines/disciplinesReducer';
import categoriesReducer from './categories/categoriesReducer';
import projectsReducer from './projects/projectsReducer';
import contactReducer from './contact/contactReducer';
import caseSharingRequestsReducer from './shares/caseSharingRequestsReducer';
import clustersReducer from './clusters/clustersReducer';
import userSettingsReducer from './userSettings/userSettingsReducer';
import solverFeedbackReducer from './solverFeedback/solverFeedbackReducer';
import usersReducer from './users/usersReducer';
import uiReducer from './ui/uiReducer';

const staticReducers = {
  auth: authReducer,
  disciplines: disciplinesReducer,
  categories: categoriesReducer,
  projects: projectsReducer,
  contact: contactReducer,
  shares: caseSharingRequestsReducer,
  clusters: clustersReducer,
  userSettings: userSettingsReducer,
  solverFeedback: solverFeedbackReducer,
  users: usersReducer,
  ui: uiReducer,
};

export default staticReducers;
