import * as actionTypes from './clustersActionTypes';
import * as api from '../../api/clustersApi';

export function fetchClustersStarted() {
  return {
    type: actionTypes.FETCH_CLUSTERS_STARTED,
  };
}

export function fetchClustersSucceeded(clusters) {
  return {
    type: actionTypes.FETCH_CLUSTERS_SUCCEEDED,
    payload: {
      clusters,
    },
  };
}

export function fetchClustersFailed(error) {
  return {
    type: actionTypes.FETCH_CLUSTERS_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchClusters() {
  return async (dispatch, getState) => {
    dispatch(fetchClustersStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.readClusters({ token: user.token });

      dispatch(fetchClustersSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchClustersFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchClustersFailed(error.detail));
      } else {
        dispatch(fetchClustersFailed(error.message));
      }
    }
  };
}
