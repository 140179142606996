import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MdNotifications as NotificationsIcon,
  MdOutlineNotifications as OutlineNotificationsIcon,
} from 'react-icons/md';
import {
  switchCurrentTab,
  toggleRightPanel,
} from '../../../state/ui/rightPanel/rightPanelActions';

import styles from './NotificationsHeaderItem.module.css';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const NotificationsHeaderItem = ({ unreadNotifications }) => {
  const dispatch = useDispatch();
  const { currentTab, isOpen } = useSelector(state => state.ui.rightPanel);

  const [animate, setAnimate] = useState(false);
  const prevUnreadNotifications = usePrevious(unreadNotifications);

  useEffect(() => {
    if (prevUnreadNotifications < unreadNotifications) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 1500);
    }
  }, [prevUnreadNotifications, unreadNotifications]);

  return (
    <button
      className={styles.notifications_button}
      type="button"
      onClick={() => {
        if (!isOpen || currentTab === 'notifications') {
          dispatch(toggleRightPanel());
        }
        dispatch(switchCurrentTab('notifications'));
      }}
    >
      <div className={animate ? styles.new_notification_animation : ''}>
        {unreadNotifications > 0 ? (
          <>
            <NotificationsIcon />
            <span>
              {unreadNotifications < 99 ? unreadNotifications : '99+'}
            </span>
          </>
        ) : (
          <OutlineNotificationsIcon />
        )}
      </div>
    </button>
  );
};

export default NotificationsHeaderItem;
