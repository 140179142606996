import * as actionTypes from './contactActionTypes';

function getInitialState() {
  return {
    values: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationErrors: {},
    isSubmitting: false,
    error: null,
    submittedOnce: false,
  };
}

export default function contactReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.CONTACT_SUBMIT_STARTED:
      return {
        ...state,
        isSubmitting: true,
        error: null,
        submittedOnce: true,
      };
    case actionTypes.CONTACT_SUBMIT_SUCCEEDED:
      return {
        ...state,
        isSubmitting: false,
        submittedOnce: false,
      };
    case actionTypes.CONTACT_SUBMIT_FAILED:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload.error,
      };
    case actionTypes.SET_CONTACT_VALUES:
      return {
        ...state,
        values: action.payload.values,
      };
    case actionTypes.SET_CONTACT_VALUES_VALIDATION:
      return {
        ...state,
        validationErrors: action.payload.errors,
      };
    default:
      return state;
  }
}
