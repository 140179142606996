import * as actionTypes from './projectsActionTypes';

const initialState = {
  isLoading: false,
  currentProjects: [],
  error: null,
  importFromSelepocoErrors: [],
  importFromSelepocoWarnings: [],
  isCreatingNewProject: false,
  isCreatingNewSelepoco: false,
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_PROJECTS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: action.payload.projects,
        error: null,
      };
    case actionTypes.FETCH_PROJECTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.CREATE_PROJECT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.CREATE_PROJECT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: [...state.currentProjects, action.payload.project],
        error: null,
        isCreatingNewProject: false,
      };
    case actionTypes.CREATE_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.CREATE_SELEPOCO_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
        importFromSelepocoErrors: [],
        importFromSelepocoWarnings: [],
      };
    case actionTypes.CREATE_SELEPOCO_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: [...state.currentProjects, action.payload.project],
        error: null,
        isCreatingNewSelepoco: false,
        importFromSelepocoWarnings: action.payload.project?.warnings
          ? action.payload.project.warnings
          : [],
      };
    case actionTypes.CREATE_SELEPOCO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        importFromSelepocoErrors: action.payload.errors,
        importFromSelepocoWarnings: action.payload.warnings,
      };

    case actionTypes.DELETE_PROJECT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.DELETE_PROJECT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: state.currentProjects.filter(
          project => project.id !== action.payload.projectId
        ),
        error: null,
      };
    case actionTypes.DELETE_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.EDIT_PROJECT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.EDIT_PROJECT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: state.currentProjects.map(p => {
          if (p.id === action.payload.project.id) {
            if (p.cases) {
              return { ...action.payload.project, cases: p.cases };
            } else {
              return action.payload.project;
            }
          }
          return p;
        }),
        error: null,
      };
    case actionTypes.EDIT_PROJECT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.DELETE_CASE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.DELETE_CASE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: state.currentProjects.map(p => ({
          ...p,
          cases: p.cases.filter(c => c.id !== action.payload.caseId),
        })),
        error: null,
      };
    case actionTypes.DELETE_CASE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.EDIT_CASE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.EDIT_CASE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: state.currentProjects.map(p => ({
          ...p,
          cases: p.cases.map(c => {
            if (c.id === action.payload.case.id) {
              return action.payload.case;
            }
            return c;
          }),
        })),
        error: null,
      };
    case actionTypes.EDIT_CASE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.CREATE_CASE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.CREATE_CASE_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        currentProjects: state.currentProjects.map(project => {
          if (project.id === action.payload.projectId) {
            return project.cases
              ? { ...project, cases: [...project.cases, action.payload.case] }
              : { ...project, cases: [action.payload.case] };
          }

          return project;
        }),
        error: null,
      };
    case actionTypes.CREATE_CASE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.OPEN_NEW_PROJECT_MODAL:
      return {
        ...state,
        isCreatingNewProject: true,
      };

    case actionTypes.CLOSE_NEW_PROJECT_MODAL:
      return {
        ...state,
        isCreatingNewProject: false,
      };

    case actionTypes.OPEN_NEW_SELEPOCO_MODAL:
      return {
        ...state,
        isCreatingNewSelepoco: true,
      };

    case actionTypes.CLOSE_NEW_SELEPOCO_MODAL:
      return {
        ...state,
        isCreatingNewSelepoco: false,
      };
    default:
      return state;
  }
}
