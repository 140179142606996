import React from 'react';

import ContactForm from '../../contact/ContactForm';
import styles from './ContactPage.module.css';

function ContactPage() {
  return (
    <div className={styles.container_contact_form}>
      <ContactForm />
    </div>
  );
}

export default ContactPage;
