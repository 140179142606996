import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { stopSimulation } from '../../state/ui/rightPanel/rightPanelActions';

const useSimulations = simulations => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');

  const deleteSimulationCard = () => {};

  const handleStopSimulation = id => {
    dispatch(stopSimulation(id));
  };

  const sortSimulations = useCallback(
    sims =>
      sims.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      ),
    []
  );

  const sortedSimulations = useMemo(
    () => sortSimulations(simulations),
    [simulations, sortSimulations]
  );

  const sortedAndFilteredSimulations = useMemo(() => {
    if (!searchTerm) {
      return sortedSimulations;
    }
    return sortedSimulations.filter(sim =>
      sim.caseName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sortedSimulations, searchTerm]);

  return {
    deleteSimulationCard,
    handleStopSimulation,
    sortedAndFilteredSimulations,
    searchTerm,
    setSearchTerm,
  };
};

export default useSimulations;
