import * as actionTypes from './userSettingsActionTypes';

function getInitialState() {
  return {
    isLoading: false,
    favoriteDiscipline: {},
    favoriteCluster: {},
    accountName: null,
    error: null,
  };
}

export default function userSettingsReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_SETTINGS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_USER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        favoriteDiscipline: action.payload.favoriteDiscipline,
        favoriteCluster: action.payload.favoriteCluster,
        accountName: action.payload.accountName,
        error: null,
      };
    case actionTypes.FETCH_USER_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.EDIT_USER_SETTINGS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.EDIT_USER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        favoriteDiscipline: action.payload.favoriteDiscipline,
        favoriteCluster: action.payload.favoriteCluster,
        accountName: action.payload.accountName,
        error: null,
      };
    case actionTypes.EDIT_USER_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.CHANGE_DISCIPLINE_USER_SETTINGS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.CHANGE_DISCIPLINE_USER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        favoriteDiscipline: action.payload.favoriteDiscipline,
        error: null,
      };
    case actionTypes.CHANGE_DISCIPLINE_USER_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.CREATE_USER_SETTINGS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.CREATE_USER_SETTINGS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        favoriteDiscipline: action.payload.favoriteDiscipline,
        favoriteCluster: action.payload.favoriteCluster,
        accountName: action.payload.accountName,
        error: null,
      };
    case actionTypes.CREATE_USER_SETTINGS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
