import { read } from '../common/services/apiHandler';

async function readDisciplines(authorization) {
  const response = await read({
    auth: authorization,
    resource: '/disciplines/',
  });

  return response;
}

export { readDisciplines };
