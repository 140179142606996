export const FETCH_CASE_SHARING_REQUESTS_STARTED =
  'FETCH_CASE_SHARING_REQUESTS_STARTED';
export const FETCH_CASE_SHARING_REQUESTS_SUCCEEDED =
  'FETCH_CASE_SHARING_REQUESTS_SUCCEEDED';
export const FETCH_CASE_SHARING_REQUESTS_FAILED =
  'FETCH_CASE_SHARING_REQUESTS_FAILED';

export const CREATE_CASE_SHARING_REQUEST_STARTED =
  'CREATE_CASE_SHARING_REQUEST_STARTED';
export const CREATE_CASE_SHARING_REQUEST_SUCCEEDED =
  'CREATE_CASE_SHARING_REQUEST_SUCCEEDED';
export const CREATE_CASE_SHARING_REQUEST_FAILED =
  'CREATE_CASE_SHARING_REQUEST_FAILED';

export const ACCEPT_CASE_SHARING_REQUEST_STARTED =
  'ACCEPT_CASE_SHARING_REQUEST_STARTED';
export const ACCEPT_CASE_SHARING_REQUEST_SUCCEEDED =
  'ACCEPT_CASE_SHARING_REQUEST_SUCCEEDED';
export const ACCEPT_CASE_SHARING_REQUEST_FAILED =
  'ACCEPT_CASE_SHARING_REQUEST_FAILED';

export const REJECT_CASE_SHARING_REQUEST_STARTED =
  'REJECT_CASE_SHARING_REQUEST_STARTED';
export const REJECT_CASE_SHARING_REQUEST_SUCCEEDED =
  'REJECT_CASE_SHARING_REQUEST_SUCCEEDED';
export const REJECT_CASE_SHARING_REQUEST_FAILED =
  'REJECT_CASE_SHARING_REQUEST_FAILED';
