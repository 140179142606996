import * as actionTypes from './disciplinesActionTypes';
import * as api from '../../api/disciplinesApi';

export function fetchDisciplinesStarted() {
  return {
    type: actionTypes.FETCH_DISCIPLINES_STARTED,
  };
}

export function fetchDisciplinesSucceeded(disciplines) {
  return {
    type: actionTypes.FETCH_DISCIPLINES_SUCCEEDED,
    payload: {
      disciplines,
    },
  };
}

export function fetchDisciplinesFailed(error) {
  return {
    type: actionTypes.FETCH_DISCIPLINES_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchDisciplines() {
  return async (dispatch, getState) => {
    dispatch(fetchDisciplinesStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.readDisciplines({ token: user.token });

      dispatch(fetchDisciplinesSucceeded(data.disciplines));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchDisciplinesFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchDisciplinesFailed(error.detail));
      } else {
        dispatch(fetchDisciplinesFailed(error.message));
      }
    }
  };
}

export function setCurrentDiscipline(discipline) {
  return {
    type: actionTypes.SET_CURRENT_DISCIPLINE,
    payload: {
      discipline,
    },
  };
}
