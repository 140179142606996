import * as actionTypes from './clustersActionTypes';

function getInitialState() {
  return {
    isLoading: false,
    items: [],
    error: null,
  };
}

export default function clustersReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_CLUSTERS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_CLUSTERS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        items: action.payload.clusters,
        error: null,
      };
    case actionTypes.FETCH_CLUSTERS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
