import React from 'react';
import useSignInForm from './useSignInForm';
import signInFormValidation from './signInFormValidation';

import styles from './signInForm.module.css';

import Loading from '../components/LoadingBar/LoadingBar';

const initialState = {
  username: '',
  password: '',
};

const SignInForm = ({ onSubmit }) => {
  const {
    handleChange,
    handleSubmit,
    errors,
    authError,
    values,
    isSubmitting,
  } = useSignInForm(initialState, signInFormValidation);
  return (
    <div className={styles['sign-in-form']}>
      {isSubmitting && <Loading />}
      <div className={styles['form-signin']}>
        <h2 className={styles['sign-in-form-title']}>Faça login para entrar</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles['sign-in-form-body']}
        >
          <input
            className={`${styles['text-input']} ${
              errors.username ? styles['text-input-error'] : ''
            }`}
            onChange={handleChange}
            value={values.username}
            name="username"
            type="text"
            placeholder="Usuário"
            autoComplete="off"
          />
          <input
            className={`${styles['text-input']} ${
              errors.password ? styles['text-input-error'] : ''
            }`}
            onChange={handleChange}
            value={values.password}
            name="password"
            type="password"
            placeholder="Senha"
            autoComplete="off"
          />
          <div className={styles['sign-in-form-error-message']}>
            {authError && <p>{authError}</p>}
            {Object.keys(errors).length
              ? errors.username || errors.password
              : ''}
          </div>
          <button
            className={styles['submit-buttom']}
            type="submit"
            disabled={Object.keys(errors).length !== 0 || isSubmitting}
          >
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignInForm;
