import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCase } from '../../../state/projects/projectsActions';

const useCaseList = () => {
  const [currentCase, setCurrentCase] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);

  const dispatch = useDispatch();

  const dispatchRemoveCaseAction = () => {
    const { id } = currentCase;
    dispatch(deleteCase(id));
    setIsRemoving(false);
  };

  const handleRemoveCase = caseItem => {
    setCurrentCase(caseItem);
    setIsRemoving(true);
  };

  return {
    dispatchRemoveCaseAction,
    isRemoving,
    setIsRemoving,
    handleRemoveCase,
    currentCase,
  };
};

export default useCaseList;
