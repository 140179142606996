import React from 'react';
import styles from './caseSharingRequest.module.css';

const CaseSharingRequest = ({
  caseSharingRequest,
  caseSharingRequestHandler,
}) => {
  const onCaseSharingRequest = event => {
    caseSharingRequestHandler(caseSharingRequest.id, event.target.id);
  };

  return (
    <div className={styles.case_sharing_request_row}>
      <div>{caseSharingRequest.case.project?.name}</div>
      <div>{caseSharingRequest.case.name}</div>
      <div>{caseSharingRequest.case.description}</div>
      <div>{caseSharingRequest.status}</div>
      <div>{caseSharingRequest.user_from.username}</div>
      <div>{`${caseSharingRequest.user_from.first_name} ${caseSharingRequest.user_from.last_name}`}</div>
      <div>{new Date(caseSharingRequest.shared_at).toLocaleString()}</div>
      <button
        id="accept"
        type="button"
        className={styles.accept}
        onClick={onCaseSharingRequest}
      >
        Aceitar
      </button>
      <button
        id="reject"
        type="button"
        className={styles.reject}
        onClick={onCaseSharingRequest}
      >
        &times;
      </button>
    </div>
  );
};

export default CaseSharingRequest;
