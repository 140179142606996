import { create, read, remove } from '../common/services/apiHandler';

async function sendSolverFeedback(
  authorization,
  solverId,
  { type, text, title, rating }
) {
  const response = await create({
    auth: authorization,
    resource: `/solvers/${solverId}/comments/`,
    body: {
      comment_type: type,
      comment: text,
      subject: title,
      rating,
    },
  });

  return response;
}

async function readSolverFeedbacks(authorization, solverId) {
  const response = await read({
    auth: authorization,
    resource: `/solvers/${solverId}/comments/`,
  });

  return response;
}

async function removeSolverFeedback(authorization, commentId) {
  const response = await remove({
    auth: authorization,
    resource: `/solvers/comments/${commentId}/`,
  });

  return response;
}

async function likeSolverFeedback(authorization, commentId) {
  const response = await create({
    auth: authorization,
    resource: `/comments/${commentId}/like/`,
  });

  return response;
}

async function dislikeSolverFeedback(authorization, commentId) {
  const response = await create({
    auth: authorization,
    resource: `/comments/${commentId}/dislike/`,
  });

  return response;
}

export {
  sendSolverFeedback,
  readSolverFeedbacks,
  removeSolverFeedback,
  likeSolverFeedback,
  dislikeSolverFeedback,
};
