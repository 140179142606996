/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';

import styles from './AlertMessage.module.css';

const VISIBILITY_TIME_LIMIT = 3000;

function AlertMessage({ content, contextualClass, dismissible = false }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (content && contextualClass) setVisible(true);
  }, [content, contextualClass]);

  useEffect(() => {
    let timeLimit;

    if (dismissible === false) {
      timeLimit = setTimeout(() => {
        setVisible(false);
      }, VISIBILITY_TIME_LIMIT);
    }

    return () => clearTimeout(timeLimit);
  }, [dismissible]);

  return (
    visible && (
      <div className={`${styles.message} ${styles[contextualClass]}`}>
        <p>{content}</p>
        {dismissible && (
          <button onClick={() => setVisible(false)}>&times;</button>
        )}
      </div>
    )
  );
}

export default AlertMessage;
