import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../../../state/auth/authActions';

const useUserDrowpdownMenu = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();

  const userName = user === null ? null : user.userName;

  function signOutHandler() {
    dispatch(signOut());
  }
  function goToSettings(closeMenu) {
    closeMenu();
    navigate('/settings');
  }

  return {
    userName,
    signOutHandler,
    goToSettings,
  };
};

export default useUserDrowpdownMenu;
