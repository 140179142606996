import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import validateCaseValues from './validateCaseValues';

import { createCase } from '../../../state/projects/projectsActions';

const useNewProjectModal = projectId => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    error: projectsError,
    isLoading,
    currentProjects,
  } = useSelector(state => state.projects);

  const [inputDataErrors, setInputDataErrors] = useState({});
  const [inputData, setInputData] = useState({
    name: '',
    description: '',
  });
  const [wasSubmitted, setWasSubmitted] = useState(false);

  useEffect(() => {
    const validationErrors = validateCaseValues(inputData);

    setInputDataErrors(validationErrors);

    if (wasSubmitted && isLoading === false && projectsError === null) {
      const project = currentProjects.find(p => p.id === projectId);
      const newCase = project.cases.find(c => c.name === inputData.name);
      if (newCase) {
        navigate(
          `/solver/${project.solver.normalized_solver_name}/case/${newCase.id}`
        );
      }
    }

    if (wasSubmitted && isLoading === false && projectsError)
      setWasSubmitted(false);
  }, [
    inputData,
    wasSubmitted,
    isLoading,
    projectsError,
    currentProjects,
    navigate,
    projectId,
  ]);

  const handleChange = e => {
    const { name, value } = e.target;

    setInputData(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleCreate = e => {
    e.preventDefault();

    dispatch(createCase(projectId, inputData));
    setWasSubmitted(true);
  };

  return {
    inputData,
    handleChange,
    handleCreate,
    inputDataErrors,
    projectsError,
    isLoading,
  };
};

export default useNewProjectModal;
