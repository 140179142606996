import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validateNewProject from './validateNewProject';

import { createProject } from '../../../state/projects/projectsActions';

const useNewProjectModal = solverId => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    name: '',
    description: '',
  });
  const { isLoading, error: creationError } = useSelector(
    state => state.projects
  );

  useEffect(() => {
    const validationErrors = validateNewProject(values);

    setErrors(validationErrors);
  }, [values]);

  const handleChange = e => {
    const { name, value } = e.target;

    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleCreate = e => {
    e.preventDefault();
    dispatch(createProject(solverId, values));
  };

  return {
    values,
    handleChange,
    handleCreate,
    errors,
    isLoading,
    creationError,
  };
};

export default useNewProjectModal;
