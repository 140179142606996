import React from 'react';
import WikkiLogoShort from '../../../assets/images/wikki-logo-short.svg';
import WikkiLogoFull from '../../../assets/images/wikki-logo-full.svg';

function WikkiLogoBelow({ collapsed }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <a href="https://www.wikki.com.br/" target="_blank" rel="noreferrer">
        {collapsed ? (
          <img
            src={WikkiLogoShort}
            alt="WIKKI Brasil"
            style={{ width: '4.5rem' }}
          />
        ) : (
          <img
            src={WikkiLogoFull}
            alt="WIKKI Brasil"
            style={{ width: '10rem' }}
          />
        )}
      </a>
    </div>
  );
}

export default WikkiLogoBelow;
