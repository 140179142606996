import { MdSearch } from 'react-icons/md';

import styles from './SearchField.module.css';

function SearchField({ value, placeholder, onChange, full = false }) {
  return (
    <div
      className={
        full ? `${styles.container} ${styles.full}` : `${styles.container}`
      }
    >
      <MdSearch />
      <input
        type="search"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default SearchField;
