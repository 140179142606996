import React from 'react';
import classes from './rating.module.css';
import { ReactComponent as FullStarIcon } from '../../assets/icons/full_star.svg';
import { ReactComponent as EmptyStarIcon } from '../../assets/icons/empty_star.svg';

export default function Rating({ rating }) {
  return (
    <div className={classes.rating}>
      {[1, 2, 3, 4, 5].map(e =>
        e <= rating ? <FullStarIcon key={e} /> : <EmptyStarIcon key={e} />
      )}
    </div>
  );
}
