import {
  read,
  create,
  partialUpdate,
  remove,
} from '../common/services/apiHandler';

async function readCaseSharingRequests(authorization) {
  const response = await read({
    auth: authorization,
    resource: '/share_case/',
  });

  return response;
}

async function createCaseSharingRequest(authorization, caseId, userId) {
  const response = await create({
    auth: authorization,
    resource: `/share_case/${caseId}/`,
    body: {
      user_id: userId,
    },
  });

  return response;
}

async function acceptCaseSharingRequest(authorization, caseSharingRequestId) {
  const response = await partialUpdate({
    auth: authorization,
    resource: `/share_case/${caseSharingRequestId}/`,
  });

  return response;
}

async function rejectCaseSharingRequest(authorization, caseSharingRequestId) {
  const response = await remove({
    auth: authorization,
    resource: `/share_case/${caseSharingRequestId}/`,
  });

  return response;
}

export {
  readCaseSharingRequests,
  createCaseSharingRequest,
  acceptCaseSharingRequest,
  rejectCaseSharingRequest,
};
