/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCaseSharingRequests } from '../../state/shares/caseSharingRequestsActions';

const useCaseSharingRequestsPage = () => {
  const dispatch = useDispatch();

  const { error, isLoading, items } = useSelector(state => state.shares);

  useEffect(() => {
    dispatch(fetchCaseSharingRequests());
  }, [dispatch]);

  return { error, isLoading, items };
};

export default useCaseSharingRequestsPage;
