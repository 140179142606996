/* eslint-disable consistent-return */
import * as actions from '../ui/rightPanel/rightPanelActions';

const socketMiddleware = () => {
  let socket = null;

  const onOpen = store => () => {
    store.dispatch(actions.socketConnected());
    socket.send(JSON.stringify({ type: 'FETCH_NOTIFICATIONS' }));
    socket.send(JSON.stringify({ type: 'FETCH_SIMULATIONS' }));
  };

  const onClose = store => () => {
    store.dispatch(actions.socketDisconnected());
  };

  const onMessage = store => event => {
    const message = JSON.parse(event.data);
    const {
      ui: { rightPanel },
    } = store.getState();
    let shouldUpdate;

    switch (message.type) {
      case 'ALL_NOTIFICATIONS':
        store.dispatch(actions.fetchNotifications(message.payload));
        break;
      case 'NEW_NOTIFICATION':
        store.dispatch(actions.addNotification(message.payload));
        break;
      case 'UPDATED_NOTIFICATION':
        store.dispatch(actions.updateNotification(message.payload));
        break;
      case 'DELETED_NOTIFICATION':
        store.dispatch(
          actions.deleteNotificationSucceeded([message.payload.id])
        );
        break;
      case 'ALL_SIMULATIONS':
        store.dispatch(actions.fetchSimulations(message.payload));
        break;
      case 'NEW_SIMULATION':
        shouldUpdate = rightPanel.simulations.items.some(
          simulation => simulation.caseId === message.payload.case_id
        );
        if (shouldUpdate) {
          store.dispatch(actions.updateSimulation(message.payload));
        } else {
          store.dispatch(actions.addSimulation(message.payload));
        }
        break;
      case 'UPDATED_SIMULATION':
        store.dispatch(actions.updateSimulation(message.payload));
        break;
      case 'DELETED_SIMULATION':
        store.dispatch(
          actions.deleteSimulationSucceeded([message.payload.job_id])
        );
        break;
      default:
        break;
    }
  };

  return store => next => action => {
    switch (action.type) {
      case 'SOCKET_CONNECT':
        if (socket && socket.readyState !== 3) {
          return next(action);
        }

        socket = new WebSocket(action.payload.url);

        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case 'SOCKET_DISCONNECT':
        if (socket) {
          socket.close();
        }
        socket = null;
        break;
      case 'SIGNOUT_STARTED':
        if (socket) {
          socket.close();
        }
        socket = null;
        return next(action);
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
