import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteProject } from '../../state/projects/projectsActions';

const useProjectList = removeProjectCallback => {
  const [isRemoving, setIsRemoving] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const dispatch = useDispatch();

  const removeProjectAction = () => {
    const { id } = currentProject;
    dispatch(deleteProject(id));
    setIsRemoving(false);
  };

  const handleRemoveProject = project => {
    const breaking = project?.cases?.some(c => c.in_simulation);
    if (breaking) {
      removeProjectCallback();
      return;
    }
    setCurrentProject(project);
    setIsRemoving(true);
  };

  return {
    handleRemoveProject,
    isRemoving,
    setIsRemoving,
    removeProjectAction,
    currentProject,
  };
};

export default useProjectList;
