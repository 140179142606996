import React from 'react';

import LoadingBar from '../../components/LoadingBar/LoadingBar';
import { AlertMessage } from '../../components/AlertMessage';
import ProjectList from '../../projects/ProjectList/ProjectList';
import useProjectsPage from './useProjectsPage';
import SearchField from '../../components/SearchField';
import RemoveProjectModal from '../../components/RemoveProjectModal';
import { ReactComponent as ProjectIcon } from '../../assets/icons/project-case-icon.svg';

import styles from './ProjectsPage.module.css';

const ProjectsPage = () => {
  const {
    error,
    projectsWithCasesFiltered,
    searchTerm,
    setSearchTerm,
    isLoading,
    alertModal,
    toggleModal,
  } = useProjectsPage();

  return (
    <>
      {isLoading && <LoadingBar />}
      {error && (
        <AlertMessage content={error} contextualClass="danger" dismissible />
      )}
      <RemoveProjectModal
        isOpen={alertModal}
        closeModal={() => toggleModal(false)}
      />
      <div className={styles.projects}>
        <div className={styles.project_header}>
          <div>
            <ProjectIcon />
            <div className={styles.heading_title}>
              <h1>Projetos</h1>
              <p>Projetos do usuário</p>
            </div>
          </div>
          <div className={styles.search}>
            <SearchField
              placeholder="Pesquisar casos do projeto"
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
        <ProjectList
          projects={projectsWithCasesFiltered()}
          removeProjectCallback={() => toggleModal(true)}
        />
      </div>
    </>
  );
};

export default ProjectsPage;
