import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentDiscipline,
  fetchDisciplines,
} from '../../../state/disciplines/disciplinesActions';

function useLeftSideBar() {
  const dispatch = useDispatch();
  const { items: disciplines, current: currentDiscipline } = useSelector(
    state => state.disciplines
  );

  useEffect(() => {
    if (Object.keys(disciplines).length === 0) {
      dispatch(fetchDisciplines());
    }
  }, [dispatch, disciplines]);

  function handleChooseDiscipline(discipline) {
    dispatch(setCurrentDiscipline(discipline));
  }

  return {
    disciplines,
    currentDiscipline,
    handleChooseDiscipline,
  };
}

export default useLeftSideBar;
