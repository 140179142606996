import { React, useState } from 'react';
import { MdWarning as WarningIcon } from 'react-icons/md';

import styles from './RemoveAllNotificationsModal.module.css';

const RemoveAllNotificationsModal = ({ removeHandler, closeModal }) => {
  const [isValid, setIsValid] = useState(false);

  const checkIfInputEqualsName = inputValue => {
    const isInputValid = inputValue === 'CONFIRMAR';

    setIsValid(isInputValid);
  };

  return (
    <div
      className={styles.modal}
      onClick={closeModal}
      aria-hidden
      data-testid="outsideModal"
    >
      <div
        className={styles.remove_modal}
        onClick={e => e.stopPropagation()}
        aria-hidden
      >
        <div className={styles.remove_header}>
          <WarningIcon />
          <div className={styles.remove_heading}>
            <h1>Deseja apagar todas as notificações?</h1>
            <p>Essa ação não poderá ser desfeita.</p>
            <p>
              Por favor digite
              <strong> CONFIRMAR</strong>
            </p>
            <input
              type="text"
              autoComplete="off"
              onChange={e => {
                const { value } = e.target;
                checkIfInputEqualsName(value);
              }}
            />
          </div>
        </div>
        <div className={styles.delete_buttons}>
          <button type="submit" disabled={!isValid} onClick={removeHandler}>
            Apagar
          </button>
          <button type="button" onClick={closeModal}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveAllNotificationsModal;
