/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleFavorite } from '../state/categories/categoriesActions';

function useMyFavorites() {
  const dispatch = useDispatch();
  const { items: categories, toggleFavoriteError: error } = useSelector(
    state => state.categories
  );
  const [categoriesToShow, setCategoriesToShow] = useState(
    []
  );

  const [favorites, setFavorites] = useState([]);

  const toggleFavoriteState = solverId => {
    dispatch(toggleFavorite(solverId));
  };

  useEffect(() => {
    const filteredCategories= categories
      .map(category => ({
        ...category,
        solvers: category.solvers.filter(solver => solver.is_favorite),
      }))
      .filter(category => category.solvers.length);
    setCategoriesToShow(filteredCategories);
  }, []);

  useEffect(() => {
    const favoritesToShow = categoriesToShow.map(category => ({
      ...category,
      solvers: category.solvers.map(solver =>
        categories
          .find(c => c.id === category.id)
          .solvers.find(s => s.id === solver.id)
      ),
    }));

    setFavorites(favoritesToShow);
  }, [categories, categoriesToShow]);

  return { favorites, toggleFavoriteState, error };
}

export default useMyFavorites;
