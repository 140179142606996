import { axiosPrivate } from './axios';

async function read({ resource, params = {} }) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await axiosPrivate.get(url.toString());

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function create({ resource, body, params = {} }) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await axiosPrivate.post(
      url.toString(),
      JSON.stringify(body)
    );

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function partialUpdate({ resource, body, params = {} }) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await axiosPrivate.patch(
      url.toString(),
      JSON.stringify(body)
    );

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function update({ resource, body, params = {} }) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await axiosPrivate.put(
      url.toString(),
      JSON.stringify(body)
    );

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function remove({ resource, body, params = {} }) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await axiosPrivate.delete(url.toString(), {
      data: JSON.stringify(body),
    });

    if (response?.status !== 204) {
      return response?.data;
    }

    return null;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function getFile({ resource, params = {} }) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await axiosPrivate.get(url.toString(), {
      responseType: 'arraybuffer',
    });

    return new Blob([response?.data], { type: 'application/json' });
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function upload({
  auth,
  resource,
  formData,
  params = {},
  onProgress = () => {},
  onStart = () => {},
  onSuccess = () => {},
  onFail = () => {},
  onError = () => {},
}) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);

  url.search = new URLSearchParams(params).toString();

  const request = new XMLHttpRequest();
  request.open('POST', url.toString(), true);

  if (auth.token) {
    request.setRequestHeader('Authorization', `JWT ${auth.token}`);
  }

  request.onloadstart = onStart;

  request.upload.onerror = error => {
    onFail(error);
  };

  request.upload.onprogress = e => {
    if (e.lengthComputable) {
      onProgress((100 * e.loaded) / e.total);
    }
  };

  request.onload = () => {
    try {
      const response = JSON.parse(request.response);
      if (request.status !== 201) {
        onFail(response);
      } else {
        onSuccess(response);
      }
    } catch (error) {
      onFail(error);
    }
  };

  request.onerror = onError;

  request.send(formData);
}

async function uploadFiles({
  auth,
  resource,
  files,
  onProgress = () => {},
  onStart = () => {},
  onSuccess = () => {},
  onFail = () => {},
  onError = () => {},
}) {
  const url = new URL(`${window.REACT_APP_PETROFOAM_API_URL}${resource}`);

  const request = new XMLHttpRequest();
  request.open('POST', url.toString(), true);

  if (auth.token) {
    request.setRequestHeader('Authorization', `JWT ${auth.token}`);
  }

  request.onloadstart = onStart;
  request.upload.onprogress = e => {
    if (e.lengthComputable) {
      onProgress((100 * e.loaded) / e.total);
    }
  };
  request.onload = () => {
    const response = JSON.parse(request.response);
    if (request.status !== 201) {
      onFail(response);
    } else {
      onSuccess(response);
    }
  };
  request.onerror = onError;

  const formData = new FormData();

  for (let i = 0; i < files.length; i += 1) {
    formData.append('input_file', files[i]);
  }

  request.send(formData);
}

export {
  read,
  create,
  partialUpdate,
  update,
  remove,
  getFile,
  upload,
  uploadFiles,
};
