import * as actionTypes from './disciplinesActionTypes';

function getInitialState() {
  return {
    isLoading: false,
    items: [],
    current: null,
    error: null,
  };
}

export default function disciplinesReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_DISCIPLINES_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_DISCIPLINES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        items: action.payload.disciplines,
        error: null,
      };
    case actionTypes.FETCH_DISCIPLINES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.SET_CURRENT_DISCIPLINE:
      return {
        ...state,
        current: action.payload.discipline,
      };
    default:
      return state;
  }
}
