export default function signInFormValidation(values) {
  const errors = {};

  if (values.password && values.password.length < 6) {
    errors.password = 'A senha deve ter mais de 6 caracteres';
  }

  if (!values.username) {
    errors.username = 'Usuário requerido';
  }

  if (!values.password) {
    errors.password = 'Senha requerida';
  }

  return errors;
}
