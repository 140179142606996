import React from 'react';
import Discipline from './components/Discipline';

import classes from './disciplines.module.css';

const Disciplines = props => {
  const { disciplines, handleChooseDiscipline, className } = props;

  return (
    <div className={`${classes['discipline-list']} ${className}`}>
      {disciplines.map(discipline => (
        <Discipline
          key={discipline.id}
          discipline={discipline}
          handleChooseDiscipline={handleChooseDiscipline}
        />
      ))}
    </div>
  );
};

export default Disciplines;
