import * as actionTypes from './caseSharingRequestsActionsType';
import * as api from '../../api/caseSharingRequestsApi';

export function fetchCaseSharingRequestsStarted() {
  return {
    type: actionTypes.FETCH_CASE_SHARING_REQUESTS_STARTED,
  };
}

export function fetchCaseSharingRequestsSucceeded(caseSharingRequests) {
  return {
    type: actionTypes.FETCH_CASE_SHARING_REQUESTS_SUCCEEDED,
    payload: {
      caseSharingRequests,
    },
  };
}

export function fetchCaseSharingRequestsFailed(error) {
  return {
    type: actionTypes.FETCH_CASE_SHARING_REQUESTS_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchCaseSharingRequests() {
  return async (dispatch, getState) => {
    dispatch(fetchCaseSharingRequestsStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.readCaseSharingRequests({ token: user.token });
      dispatch(fetchCaseSharingRequestsSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchCaseSharingRequestsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchCaseSharingRequestsFailed(error.detail));
      } else {
        dispatch(
          fetchCaseSharingRequestsFailed(
            'Não foi possível obter os dados. Rede ou servidor inacessível.'
          )
        );
      }
    }
  };
}

export function createCaseSharingRequestStarted() {
  return {
    type: actionTypes.CREATE_CASE_SHARING_REQUEST_STARTED,
  };
}

export function createCaseSharingRequestSucceeded() {
  return {
    type: actionTypes.CREATE_CASE_SHARING_REQUEST_SUCCEEDED,
  };
}

export function createCaseSharingRequestFailed(error) {
  return {
    type: actionTypes.CREATE_CASE_SHARING_REQUEST_FAILED,
    payload: {
      error,
    },
  };
}

export function createCaseSharingRequest(caseId, userId, onSuccess) {
  return async (dispatch, getState) => {
    dispatch(createCaseSharingRequestStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await api.createCaseSharingRequest({ token: user.token }, caseId, userId);
      dispatch(createCaseSharingRequestSucceeded());
      onSuccess?.();
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(createCaseSharingRequestFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(createCaseSharingRequestFailed(error.detail));
      } else {
        dispatch(createCaseSharingRequestFailed('Falha no compartilhamento'));
      }
    }
  };
}

export function acceptCaseSharingRequestStarted() {
  return {
    type: actionTypes.ACCEPT_CASE_SHARING_REQUEST_STARTED,
  };
}

export function acceptCaseSharingRequestSucceeded(caseSharingRequestId) {
  return {
    type: actionTypes.ACCEPT_CASE_SHARING_REQUEST_SUCCEEDED,
    payload: {
      caseSharingRequestId,
    },
  };
}

export function acceptCaseSharingRequestFailed(error) {
  return {
    type: actionTypes.ACCEPT_CASE_SHARING_REQUEST_FAILED,
    payload: {
      error,
    },
  };
}

export function acceptCaseSharingRequest(caseSharingRequestId) {
  return async (dispatch, getState) => {
    dispatch(acceptCaseSharingRequestStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await api.acceptCaseSharingRequest(
        { token: user.token },
        caseSharingRequestId
      );
      dispatch(acceptCaseSharingRequestSucceeded(caseSharingRequestId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(acceptCaseSharingRequestFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(acceptCaseSharingRequestFailed(error.detail));
      } else {
        dispatch(
          acceptCaseSharingRequestFailed(
            'Falha ao aceitar o compartilhamento. Tente mais tarde.'
          )
        );
      }
    }
  };
}

export function rejectCaseSharingRequestStarted() {
  return {
    type: actionTypes.REJECT_CASE_SHARING_REQUEST_STARTED,
  };
}

export function rejectCaseSharingRequestSucceeded(caseSharingRequestId) {
  return {
    type: actionTypes.REJECT_CASE_SHARING_REQUEST_SUCCEEDED,
    payload: {
      caseSharingRequestId,
    },
  };
}

export function rejectCaseSharingRequestFailed(error) {
  return {
    type: actionTypes.REJECT_CASE_SHARING_REQUEST_FAILED,
    payload: {
      error,
    },
  };
}

export function rejectCaseSharingRequest(caseSharingRequestId) {
  return async (dispatch, getState) => {
    dispatch(rejectCaseSharingRequestStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await api.rejectCaseSharingRequest(
        { token: user.token },
        caseSharingRequestId
      );
      dispatch(rejectCaseSharingRequestSucceeded(caseSharingRequestId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(rejectCaseSharingRequestFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(rejectCaseSharingRequestFailed(error.detail));
      } else {
        dispatch(
          rejectCaseSharingRequestFailed(
            'Falha ao rejeitar o compartilhamento. Tente novamente em alguns minutos.'
          )
        );
      }
    }
  };
}
