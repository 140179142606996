import {
  ADD_NOTIFICATION,
  ADD_SIMULATION,
  MARK_NOTIFICATIONS_AS_READ_STARTED,
  MARK_NOTIFICATIONS_AS_READ_SUCCEEDED,
  MARK_NOTIFICATIONS_AS_READ_FAILED,
  DELETE_NOTIFICATION_FAILED,
  DELETE_NOTIFICATION_STARTED,
  DELETE_NOTIFICATION_SUCCEEDED,
  FETCH_NOTIFICATIONS,
  FETCH_SIMULATIONS,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  STOP_SIMULATION_FAILED,
  STOP_SIMULATION_STARTED,
  STOP_SIMULATION_SUCCEEDED,
  SWITCH_CURRENT_TAB,
  TOGGLE_RIGHT_PANEL,
  UPDATE_NOTIFICATION,
  UPDATE_SIMULATION,
  DELETE_SIMULATION_SUCCEEDED,
} from './rightPanelActionsTypes';

const initialState = {
  isOpen: false,
  currentTab: 'simulations',
  connectionStatus: 'SOCKET_DISCONNECTED',
  notifications: {
    items: [],
    isLoading: false,
    error: null,
  },
  simulations: {
    items: [],
    isLoading: false,
    error: null,
  },
};

export default function rightPanelReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_RIGHT_PANEL:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case SWITCH_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload.currentTab,
      };
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          items: action.payload.notifications,
        },
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: {
          items: [action.payload.notification, ...state.notifications.items],
        },
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notifications: {
          items: state.notifications.items.map(notification =>
            notification.id === action.payload.notification.id
              ? action.payload.notification
              : notification
          ),
        },
      };
    case MARK_NOTIFICATIONS_AS_READ_STARTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
          error: null,
        },
      };
    case MARK_NOTIFICATIONS_AS_READ_SUCCEEDED:
      return {
        ...state,
        notifications: {
          items: state.notifications.items.map(notification =>
            action.payload.notificationIds.includes(notification.id)
              ? { ...notification, read: true }
              : notification
          ),
          isLoading: false,
          error: null,
        },
      };
    case MARK_NOTIFICATIONS_AS_READ_FAILED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case DELETE_NOTIFICATION_STARTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
          error: null,
        },
      };
    case DELETE_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        notifications: {
          items: state.notifications.items.filter(
            notification =>
              !action.payload.notificationIds.includes(notification.id)
          ),
          isLoading: false,
          error: null,
        },
      };
    case DELETE_NOTIFICATION_FAILED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case FETCH_SIMULATIONS:
      return {
        ...state,
        simulations: {
          items: action.payload.simulations.map(simulation => ({
            jobId: simulation.job_id,
            jobStatus: simulation.job_status,
            caseId: simulation.case_id,
            caseName: simulation.case_name,
            simulationPercentage: simulation.simulation_percentage,
            solverIdentifier: simulation.solver_identifier,
            updatedAt: simulation.updated_at,
          })),
        },
      };
    case ADD_SIMULATION:
      return {
        ...state,
        simulations: {
          ...state.simulations,
          items: [action.payload.simulation, ...state.simulations.items],
        },
      };
    case UPDATE_SIMULATION:
      return {
        ...state,
        simulations: {
          items: state.simulations.items.map(simulation =>
            simulation.caseId === action.payload.simulation.caseId
              ? action.payload.simulation
              : simulation
          ),
        },
      };
    case STOP_SIMULATION_STARTED:
      return {
        ...state,
        simulations: {
          ...state.simulations,
          isLoading: true,
          error: null,
        },
      };
    case STOP_SIMULATION_SUCCEEDED:
      return {
        ...state,
        simulations: {
          items: state.simulations.items.map(simulation =>
            simulation.caseId === action.payload.simulationId
              ? {
                  ...simulation,
                  status: 'INTERROMPIDO',
                  simulationPercentage: 100,
                }
              : simulation
          ),
          isLoading: false,
          error: null,
        },
      };
    case STOP_SIMULATION_FAILED:
      return {
        ...state,
        simulations: {
          ...state.simulations,
          isLoading: false,
          error: action.payload.error,
        },
      };
    case DELETE_SIMULATION_SUCCEEDED:
      return {
        ...state,
        simulations: {
          items: state.simulations.items.filter(
            simulation =>
              !action.payload.simulationIds.includes(simulation.jobId)
          ),
        },
      };
    case SOCKET_CONNECTED:
      return {
        ...state,
        connectionStatus: 'SOCKET_CONNECTED',
      };
    case SOCKET_DISCONNECTED:
      return {
        ...state,
        connectionStatus: 'SOCKET_DISCONNECTED',
      };
    default:
      return state;
  }
}
