import React from 'react';
import useSolverPage from './useSolverPage';
import SolverBanner from './components/SolverBanner';
import FeedbackSection from './components/FeedbackSection';

function SolverPage() {
  const { solver, toggleFavorite } = useSolverPage();

  return (
    <>
      <SolverBanner solver={solver} toggleFavorite={toggleFavorite} />
      <FeedbackSection solverId={solver.id} />
    </>
  );
}

export default SolverPage;
