import React from 'react';
import styles from './DropdownMenuItem.module.css';

const DropdownMenuItem = ({ children, leftIcon, handler }) => (
  <button className={styles.menu_item} type="button" onClick={handler}>
    {leftIcon && <span className={styles.icon_item}>{leftIcon}</span>}
    {children}
  </button>
);

export default DropdownMenuItem;
