import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LoginSectionContent from './LoginSection/LoginSectionContent';
import AboutSectionContent from './AboutSection/AboutSectionContent';
import TestimonialsSectionContent from './TestimonialsSection/TestimonialsSectionContet';

import styles from './homePage.module.css';

const HomePage = () => {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();

  if (user?.is_staff && user?.username === 'admin') {
    return <Navigate to="/admin" state={{ from: location }} replace />;
  }

  if (
    user &&
    user?.is_staff === false &&
    location?.state?.from?.pathname &&
    location?.state?.from?.pathname?.includes('/solver/prodpoco/case/')
  ) {
    return (
      <Navigate
        to={location.state.from.pathname}
        state={{ from: location }}
        replace
      />
    );
  }

  if (user && user?.is_staff === false) {
    return <Navigate to="/disciplines" state={{ from: location }} replace />;
  }

  return (
    <div className={styles['home-page']}>
      <LoginSectionContent />
      <AboutSectionContent />
      <TestimonialsSectionContent />
    </div>
  );
};

export default HomePage;
