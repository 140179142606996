import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useDisciplinesPage from './useDisciplinesPage';
import { Disciplines } from '../../disciplines';
import petrofoamLogo from '../../assets/images/petrofoam-logo.svg';

import classes from './disciplinesPage.module.css';

const DisciplinesPage = () => {
  const { disciplines, selectedDiscipline, handleChooseDiscipline } =
    useDisciplinesPage();
  const location = useLocation();

  return selectedDiscipline ? (
    <Navigate to="/categories" state={{ from: location }} replace />
  ) : (
    <div className={classes['disciplines-page']}>
      <div className={classes['logo-top']}>
        <img src={petrofoamLogo} alt="PetroFOAM" />
      </div>
      <div className={classes.subtitle}>SELECIONE UMA DISCIPLINA</div>
      <Disciplines
        className={classes['discipline-list']}
        disciplines={disciplines}
        handleChooseDiscipline={handleChooseDiscipline}
      />
    </div>
  );
};

export default DisciplinesPage;
