import React from 'react';
import { AlertMessage } from '../../components/AlertMessage';
import useCaseSharingRequestsPage from './useCaseSharingRequestsPage';
import CaseSharingRequestList from '../../shares';

import styles from './caseSharingRequestsPage.module.css';
import { ReactComponent as CaseIcon } from '../../assets/icons/project-case-icon.svg';
import LoadingBar from '../../components/LoadingBar/LoadingBar';

const CaseSharingRequestsPage = () => {
  const {
    error,
    isLoading,
    items: caseSharingRequestList,
  } = useCaseSharingRequestsPage();

  return (
    <>
      {isLoading && <LoadingBar />}
      {error && (
        <AlertMessage content={error} contextualClass="danger" dismissible />
      )}
      {isLoading === false &&
        error === null &&
        caseSharingRequestList.length < 1 && (
          <AlertMessage
            content="Não há novos pedidos de compartilhamento."
            contextualClass="info"
            dismissible
          />
        )}
      {caseSharingRequestList.length > 0 && (
        <div className={styles.case_sharing_requests_page}>
          <div className={styles.header}>
            <CaseIcon />
            <div>
              <h3>Compartilhamento de Casos</h3>
              <p>Gerencie seus pedidos de Compartilhamento</p>
            </div>
          </div>
          <div className={styles.body}>
            <CaseSharingRequestList
              caseSharingRequestList={caseSharingRequestList}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CaseSharingRequestsPage;
