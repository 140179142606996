import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './leftMainPanel.module.css';
import projects from './assets/projects.svg';
import favorites from './assets/favorites.svg';
import shares from './assets/shares.svg';
import contact from './assets/contact.svg';

const LeftMainPanel = ({ collapsed }) => {
  const links = useRef([
    { icon: projects, name: 'Meus Projetos', href: '/projects' },
    { icon: favorites, name: 'Meus Favoritos', href: '/favorites' },
    { icon: shares, name: 'Compartilhamentos', href: '/shares' },
    { icon: contact, name: 'Fale conosco', href: '/contact' },
  ]);

  return (
    <div
      className={`${classes['left-main-panel']} ${
        collapsed ? '' : classes['is-open']
      }`}
    >
      {links.current.map(link => (
        <NavLink
          key={link.name}
          className={classes.item}
          // activeClassName={classes['active-item']}
          to={link.href}
          exact="true"
        >
          <img className={classes.icon} src={link.icon} alt={link.name} />
          {!collapsed && <p>{link.name}</p>}
        </NavLink>
      ))}
    </div>
  );
};

export default LeftMainPanel;
