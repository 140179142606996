export const FETCH_PROJECTS_STARTED = 'FETCH_PROJECTS_STARTED';
export const FETCH_PROJECTS_SUCCEEDED = 'FETCH_PROJECTS_SUCCEEDED';
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED';

export const CREATE_PROJECT_STARTED = 'CREATE_PROJECT_STARTED';
export const CREATE_PROJECT_SUCCEEDED = 'CREATE_PROJECT_SUCCEEDED';
export const CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED';

export const CREATE_SELEPOCO_STARTED = 'CREATE_SELEPOCO_STARTED';
export const CREATE_SELEPOCO_SUCCEEDED = 'CREATE_SELEPOCO_SUCCEEDED';
export const CREATE_SELEPOCO_FAILED = 'CREATE_SELEPOCO_FAILED';

export const DELETE_PROJECT_STARTED = 'DELETE_PROJECT_STARTED';
export const DELETE_PROJECT_SUCCEEDED = 'DELETE_PROJECT_SUCCEEDED';
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED';

export const EDIT_PROJECT_STARTED = 'EDIT_PROJECT_STARTED';
export const EDIT_PROJECT_SUCCEEDED = 'EDIT_PROJECT_SUCCEEDED';
export const EDIT_PROJECT_FAILED = 'EDIT_PROJECT_FAILED';

export const DELETE_CASE_STARTED = 'DELETE_CASE_STARTED';
export const DELETE_CASE_SUCCEEDED = 'DELETE_CASE_SUCCEEDED';
export const DELETE_CASE_FAILED = 'DELETE_CASE_FAILED';

export const EDIT_CASE_STARTED = 'EDIT_CASE_STARTED';
export const EDIT_CASE_SUCCEEDED = 'EDIT_CASE_SUCCEEDED';
export const EDIT_CASE_FAILED = 'EDIT_CASE_FAILED';

export const CREATE_CASE_STARTED = 'CREATE_CASE_STARTED';
export const CREATE_CASE_SUCCEEDED = 'CREATE_CASE_SUCCEEDED';
export const CREATE_CASE_FAILED = 'CREATE_CASE_FAILED';

export const OPEN_NEW_PROJECT_MODAL = 'OPEN_NEW_PROJECT_MODAL';
export const CLOSE_NEW_PROJECT_MODAL = 'CLOSE_NEW_PROJECT_MODAL';

export const OPEN_NEW_SELEPOCO_MODAL = 'OPEN_NEW_SELEPOCO_MODAL';
export const CLOSE_NEW_SELEPOCO_MODAL = 'CLOSE_NEW_SELEPOCO_MODAL';
