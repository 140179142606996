import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  submitContact,
  setContactValues,
} from '../state/contact/contactActions';

function useContactForm(validate) {
  const { values, error, isSubmitting } = useSelector(
    state => state.contact
  );
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState({});
  const submittedOnce = useRef(false);

  function handleChange(event) {
    const { name, value } = event.target;
    const newValues = {
      ...values,
      [name]: value,
    };
    dispatch(setContactValues(newValues));
  }

  useEffect(() => {
    if (submittedOnce.current) {
      const errors = validate(values);
      setValidationErrors(errors);
    }
  }, [validate, values, submittedOnce, dispatch]);

  function handleSubmit(onSubmit) {
    return event => {
      event.preventDefault();
      onSubmit?.(event);
      let errors = validationErrors;
      if (!submittedOnce.current) {
        errors = validate(values);
        setValidationErrors(errors);
      }
      const isFormValid = Object.keys(errors).length === 0;
      if (isFormValid) {
        dispatch(submitContact());
      } else {
        submittedOnce.current = true;
      }
    };
  }

  return {
    values,
    error,
    validationErrors,
    isSubmitting,
    handleChange,
    handleSubmit,
  };
}

export default useContactForm;
