import axios from './axios';
import store from '../../state/store';

const refreshToken = async () => {
  const response = await axios.get('/users/refresh_token/', {
    withCredentials: true,
  });

  store.dispatch({
    type: 'ACCESS_TOKEN_UPDATE',
    payload: {
      newAccessToken: response?.data?.access,
    },
  });

  return response?.data?.access;
};

export default refreshToken;
