import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { editProject } from '../../../state/projects/projectsActions';
import { exportSelepoco as exportSelepocoApi } from '../../../api/projectsApi';
import validateProjectUpdate from './validateProjectUpdate';

const useProjectItem = project => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(state => state.auth.user);

  const [showCases, setShowCases] = useState(false);
  const [isCreatingNewCase, setIsCreatingNewCase] = useState(false);

  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState({
    name: project.name,
    description: project.description,
  });

  useEffect(() => {
    const validationErrors = validateProjectUpdate(values);

    setErrors(validationErrors);
  }, [values]);

  const handleChange = e => {
    const { name, value } = e.target;

    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const handleUpdateProject = event => {
    event.preventDefault();

    if (Object.keys(errors).length) {
      return;
    }

    dispatch(editProject(project.id, values));

    setIsEditing(false);
  };

  const runMultiplesCases = () => {
    navigate(`/prodpoco/cases/${project.id}`, { replace: true });
  };

  const generalDownload = (data, name) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  };

  const exportSelepoco = async () => {
    const cases = project.cases.filter(c => c.has_results === 1).map(c => c.id);

    try {
      const response = await exportSelepocoApi({ token }, { id_cases: cases });
      const jsonse = JSON.stringify(
        { configurationOptions: response.configurationOptions },
        null,
        2
      );

      const suggestedName = `${project.solver?.name}_project_${project.id} - ${project.name}.json`;
      const blob = new Blob([jsonse], {
        type: 'application/json',
      });
      if (!window.showSaveFilePicker) {
        generalDownload(blob, suggestedName);
      } else {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName,
          types: [
            {
              accept: {
                'text/json': ['.json'],
              },
            },
          ],
        });
        const fileStream = await fileHandle.createWritable();
        await fileStream.write(blob);
        await fileStream.close();
      }
    } finally {
      // End
    }
  };

  return {
    showCases,
    setShowCases,
    values,
    handleChange,
    isEditing,
    setIsEditing,
    handleUpdateProject,
    errors,
    isCreatingNewCase,
    setIsCreatingNewCase,
    runMultiplesCases,
    exportSelepoco,
  };
};

export default useProjectItem;
