import React from 'react';
import { Link } from 'react-router-dom';
import Rating from './components/Rating';
import styles from './solverFeatured.module.css';
import wellReservoirThumbnail from '../assets/images/produtividade_thumbnail.png';
import acidPlugThumbnail from '../assets/images/acidPlug_thumbnail.png';
import krelThumbnail from '../assets/images/krel_thumbnail.jpg';
import kabsThumbnail from '../assets/images/kabs_thumbnail.jpg';

export default function SolverFeatured({
  solver,
  onFavorite,
  hasButtons = true,
}) {
  const {
    id,
    category,
    name,
    rating,
    description,
    identifier,
    created_at: createdAt,
    is_favorite: isFavorite,
  } = solver;

  const thumbnail = {
    WellReservoir: wellReservoirThumbnail,
    PlugAcidification: acidPlugThumbnail,
    Krel: krelThumbnail,
    Kabs: kabsThumbnail,
  }[identifier];

  return (
    <div
      style={{
        backgroundColor: '#182222',
        background: `linear-gradient(90deg, #172222 0,rgba(23,34,34,.47) 53%,rgba(23,34,34,.43) 57%,rgba(23,34,34,.29) 71%,rgba(23,34,34,0)) 240px 50% / 700px no-repeat,
        linear-gradient(180deg, rgba(23,34,34,0) 0,rgba(23,34,34,.1) 20%,rgba(23,34,34,.3) 45%,rgba(23,34,34,.6) 75%,#172222) 0% 100% / 100% 100px no-repeat,
        url(${thumbnail}) 240px 50% / cover no-repeat`,
      }}
      className={styles.container}
    >
      <div
        className={`${styles.content} ${hasButtons ? styles.hasButtons : ''}`}
      >
        <div className={styles.category}>{category}</div>
        <div className={styles.name}>{name}</div>
        <div className={styles.details}>
          <Rating rating={rating} />
          <div>
            {new Date(createdAt).getFullYear()}
            {' | '}
            Wikki Brasil
          </div>
        </div>
        <p className={styles.description}>{description}</p>
        {hasButtons && (
          <div className={styles['button-group']}>
            <Link to={`/solvers/${id}`} className={styles.button}>
              Entrar
            </Link>
            <button
              type="button"
              className={`${styles['toggle-button']} ${
                isFavorite ? styles['toggle-button-active'] : ''
              }`}
              onClick={() => onFavorite(id)}
            >
              {isFavorite ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                      fill="currentColor"
                    />
                  </svg>
                  Favorito
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
                      fill="currentColor"
                    />
                  </svg>
                  Favoritar
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
