import { useDispatch } from 'react-redux';
import {
  acceptCaseSharingRequest,
  rejectCaseSharingRequest,
} from '../state/shares/caseSharingRequestsActions';

const useCaseSharingRequestList = () => {
  const dispatch = useDispatch();

  const caseSharingRequestHandler = (caseSharingRequestId, action) => {
    if (action === 'accept') {
      dispatch(acceptCaseSharingRequest(caseSharingRequestId));
    } else {
      dispatch(rejectCaseSharingRequest(caseSharingRequestId));
    }
  };

  return { caseSharingRequestHandler };
};

export default useCaseSharingRequestList;
