import React from 'react';
import { Link } from 'react-router-dom';
import Rating from '../../../categories/components/Rating';
import styles from './SolverBanner.module.css';
import wellReservoirThumbnail from '../../../assets/images/produtividade_thumbnail.png';
import acidPlugThumbnail from '../../../assets/images/acidPlug_thumbnail.png';
import krelThumbnail from '../../../assets/images/krel_thumbnail.jpg';
import kabsThumbnail from '../../../assets/images/kabs_thumbnail.jpg';

const SolverBanner = ({ solver, toggleFavorite }) => {
  const {
    id,
    name,
    description,
    rating,
    created_at: createdAt,
    identifier,
    is_favorite: isFavorite,
  } = solver;

  const thumbnail = {
    WellReservoir: wellReservoirThumbnail,
    PlugAcidification: acidPlugThumbnail,
    Krel: krelThumbnail,
    Kabs: kabsThumbnail,
  }[identifier];

  return (
    <div className={styles.container}>
      <div className={styles.image_container}>
        <img alt="solver" src={thumbnail} />
      </div>
      <h3>{name}</h3>

      <div className={styles.info}>
        <div className={styles.details}>
          <Rating rating={rating} />
          <span>
            {new Date(createdAt).getFullYear()}
            {' | '}
            Wikki Brasil
          </span>
        </div>

        <div className={styles.button_group}>
          <Link to={`/solvers/${id}/projects`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
            </svg>
            Meus projetos
          </Link>
          <button
            className={isFavorite ? styles.active : ''}
            type="button"
            onClick={() => toggleFavorite(id)}
          >
            {isFavorite ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
                    fill="currentColor"
                  />
                </svg>
                Favorito
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
                    fill="currentColor"
                  />
                </svg>
                Favoritar
              </>
            )}
          </button>
        </div>
      </div>

      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default SolverBanner;
