import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Navigation from '../components/LeftPanel/LeftPanel';
import Notification from '../rightPanel';
import Toolbar from '../components/Header';

import { toggleLeftPanel } from '../state/ui/leftPanel/leftPanelActions';

import styles from './Layout.module.css';

const Layout = ({ children }) => {
  const { collapsed } = useSelector(state => state.ui.leftPanel);
  const { currentTab, isOpen } = useSelector(state => state.ui.rightPanel);
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <header>
        <Toolbar onClickMenu={() => dispatch(toggleLeftPanel())} />
      </header>

      <nav>
        <Navigation collapsed={collapsed} hasLeftNavBar />
      </nav>

      <main>{children}</main>

      <aside>{isOpen && <Notification currentTab={currentTab} />}</aside>
    </div>
  );
};

export default Layout;
