import React from 'react';

const ToolTip = ({ children, tooltip }) => {
  return (
    <div className={`group relative inline-flex items-center my-0 mx-2`}>
      {children}
      <span
        className={`hidden absolute left-1/2 transform -translate-x-1/2 top-[calc(100%+1rem)] w-80 
          group-hover:block group-hover:bg-[#344a4a] group-hover:text-[#b5f7ed] group-hover:rounded-md 
          group-hover:text-2xl group-hover:leading-normal group-hover:py-2 group-hover:px-6 group-hover:whitespace-normal 
          group-hover:drop-shadow-2xl group-hover:text-justify group-hover:w-[27rem] group-hover:z-10
          before:absolute before:bottom-full before:left-1/2 before:transform before:-translate-x-1/2
          before:border-[0.5rem] before:border-transparent before:border-solid before:border-t before:border-t-gray-700
          `}
      >
        {tooltip}
      </span>
    </div>
  );
};

export default ToolTip;
