import { axiosPrivate } from '../common/services/axios';

async function fetchCategoriesByDisciplineId(disciplineId) {
  try {
    const response = await axiosPrivate.get(
      `/disciplines/${disciplineId}/categories/`
    );

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

export { fetchCategoriesByDisciplineId };
