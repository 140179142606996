import {
  MdHighlightOff as CloseIcon,
  MdStopCircle as StopIcon,
} from 'react-icons/md';

import styles from './SimulationItem.module.css';

const useSimulationItem = jobStatus => {
  let ChangeStatusIcon = CloseIcon;

  if (
    jobStatus === 'NA FILA' ||
    jobStatus === 'NA FILA PARA PRÉ-PROCESSAR' ||
    jobStatus === 'EM PRÉ-PROCESSAMENTO' ||
    jobStatus === 'SIMULANDO'
  ) {
    ChangeStatusIcon = StopIcon;
  }

  const statusColors = {
    SIMULANDO: styles.blue,
    'NA FILA': styles.blue,
    FINALIZANDO: styles.blue,
    'EM PRÉ-PROCESSAMENTO': styles.blue,
    'NA FILA PARA PRÉ-PROCESSAR': styles.blue,
    COMPLETO: styles.green,
    'PRÉ-PROCESSADO': styles.green,
    INTERROMPIDO: styles.yellow,
    'INTERROMPIDO NO PRÉ-PROCESSAMENTO': styles.yellow,
    'FALHA NA EXECUÇÃO': styles.red,
    'FALHA NO PRÉ-PROCESSAMENTO': styles.red,
  };

  const cardColor = statusColors[jobStatus];

  return { ChangeStatusIcon, cardColor };
};

export default useSimulationItem;
