import React from 'react';
import {
  MdClose as CloseIcon,
  MdDeleteSweep as DeleteAllIcon,
} from 'react-icons/md';
import Simulations from './components/Simulations';
import Notifications from './components/Notifications';
import RemoveAllNotificationsModal from './components/RemoveAllNotificationsModal';
import useRightPanel from './useRightPanel';

import styles from './RightPanel.module.css';

const RightPanel = ({ currentTab }) => {
  const {
    notifications,
    simulations,
    openModal,
    setOpenModal,
    removeHandler,
    togglePanel,
    switchTab,
  } = useRightPanel();

  return (
    <>
      <div className={styles.right_panel}>
        <div className={styles.header}>
          <button
            className={currentTab === 'simulations' ? styles.selected : ''}
            type="button"
            onClick={() => {
              switchTab('simulations');
            }}
          >
            Simulações
          </button>
          <button
            className={currentTab === 'notifications' ? styles.selected : ''}
            type="button"
            onClick={() => {
              switchTab('notifications');
            }}
          >
            Notificações
          </button>
          {currentTab === 'notifications' && (
            <button type="button" onClick={() => setOpenModal(true)}>
              <DeleteAllIcon />
            </button>
          )}
          <button type="button" onClick={togglePanel}>
            <CloseIcon />
          </button>
        </div>
        {currentTab === 'simulations' ? (
          <Simulations simulations={simulations.items} />
        ) : (
          <Notifications notifications={notifications.items} />
        )}
      </div>
      {openModal && (
        <RemoveAllNotificationsModal
          removeHandler={removeHandler}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default RightPanel;
