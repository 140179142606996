import * as actionTypes from './rightPanelActionsTypes';
import * as api from '../../../api/simulationsApi';
import * as notificationsApi from '../../../api/notificationsApi';

export function switchCurrentTab(currentTab) {
  return {
    type: actionTypes.SWITCH_CURRENT_TAB,
    payload: {
      currentTab,
    },
  };
}

export function toggleRightPanel() {
  return {
    type: actionTypes.TOGGLE_RIGHT_PANEL,
  };
}

export function fetchNotifications(notifications) {
  return {
    type: actionTypes.FETCH_NOTIFICATIONS,
    payload: {
      notifications: notifications.map(notification => ({
        id: notification.id,
        message: notification.message,
        messageType: notification.message_type,
        createdAt: notification.created_at,
        read: notification.read,
      })),
    },
  };
}

export function addNotification(notification) {
  return {
    type: actionTypes.ADD_NOTIFICATION,
    payload: {
      notification: {
        id: notification.id,
        message: notification.message,
        messageType: notification.message_type,
        createdAt: notification.created_at,
        read: notification.read,
      },
    },
  };
}

export function updateNotification(notification) {
  return {
    type: actionTypes.UPDATE_NOTIFICATION,
    payload: {
      notification: {
        id: notification.id,
        message: notification.message,
        messageType: notification.message_type,
        createdAt: notification.created_at,
        read: notification.read,
      },
    },
  };
}

export function markNotificationsAsReadStarted() {
  return {
    type: actionTypes.MARK_NOTIFICATIONS_AS_READ_STARTED,
  };
}

export function markNotificationsAsReadSucceeded(notificationIds) {
  return {
    type: actionTypes.MARK_NOTIFICATIONS_AS_READ_SUCCEEDED,
    payload: {
      notificationIds,
    },
  };
}

export function markNotificationsAsReadFailed(error) {
  return {
    type: actionTypes.MARK_NOTIFICATIONS_AS_READ_FAILED,
    payload: {
      error,
    },
  };
}

export function markNotificationsAsRead(notificationIds) {
  return async (dispatch, getState) => {
    dispatch(markNotificationsAsReadStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await notificationsApi.markNotificationsAsRead(
        { token: user.token },
        notificationIds
      );

      dispatch(markNotificationsAsReadSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(markNotificationsAsReadFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(markNotificationsAsReadFailed(error.detail));
      } else {
        dispatch(markNotificationsAsReadFailed(error.message));
      }
    }
  };
}

export function deleteNotificationStarted() {
  return {
    type: actionTypes.DELETE_NOTIFICATION_STARTED,
  };
}

export function deleteNotificationSucceeded(notificationIds) {
  return {
    type: actionTypes.DELETE_NOTIFICATION_SUCCEEDED,
    payload: {
      notificationIds,
    },
  };
}

export function deleteNotificationFailed(error) {
  return {
    type: actionTypes.DELETE_NOTIFICATION_FAILED,
    payload: {
      error,
    },
  };
}

export function deleteNotification(notificationIds) {
  return async (dispatch, getState) => {
    dispatch(deleteNotificationStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await notificationsApi.removeNotifications(
        { token: user.token },
        notificationIds
      );

      dispatch(deleteNotificationSucceeded(notificationIds));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(deleteNotificationFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(deleteNotificationFailed(error.detail));
      } else {
        dispatch(deleteNotificationFailed(error.message));
      }
    }
  };
}

export function addSimulation(simulation) {
  return {
    type: actionTypes.ADD_SIMULATION,
    payload: {
      simulation: {
        jobId: simulation.job_id,
        jobStatus: simulation.job_status,
        caseId: simulation.case_id,
        caseName: simulation.case_name,
        simulationPercentage: simulation.simulation_percentage,
        solverIdentifier: simulation.solver_identifier,
        updatedAt: simulation.updated_at,
      },
    },
  };
}

export function fetchSimulations(simulations) {
  return {
    type: actionTypes.FETCH_SIMULATIONS,
    payload: { simulations },
  };
}

export function updateSimulation(simulation) {
  return {
    type: actionTypes.UPDATE_SIMULATION,
    payload: {
      simulation: {
        jobId: simulation.job_id,
        jobStatus: simulation.job_status,
        caseId: simulation.case_id,
        caseName: simulation.case_name,
        simulationPercentage: simulation.simulation_percentage,
        solverIdentifier: simulation.solver_identifier,
        updatedAt: simulation.updated_at,
      },
    },
  };
}

export function stopSimulationStarted() {
  return {
    type: actionTypes.STOP_SIMULATION_STARTED,
  };
}

export function stopSimulationSucceeded(simulationId) {
  return {
    type: actionTypes.STOP_SIMULATION_SUCCEEDED,
    payload: {
      simulationId,
    },
  };
}

export function stopSimulationFailed(error) {
  return {
    type: actionTypes.STOP_SIMULATION_FAILED,
    payload: {
      error,
    },
  };
}

export function stopSimulation(simulationId) {
  return async (dispatch, getState) => {
    dispatch(stopSimulationStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await api.removeSimulation({ token: user.token }, simulationId);

      dispatch(stopSimulationSucceeded());
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(stopSimulationFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(stopSimulationFailed(error.detail));
      } else {
        dispatch(stopSimulationFailed(error.message));
      }
    }
  };
}

export function deleteSimulationSucceeded(simulationIds) {
  return {
    type: actionTypes.DELETE_SIMULATION_SUCCEEDED,
    payload: {
      simulationIds,
    },
  };
}

export function socketConnect(url) {
  return {
    type: actionTypes.SOCKET_CONNECT,
    payload: { url },
  };
}

export function socketConnected() {
  return {
    type: actionTypes.SOCKET_CONNECTED,
  };
}

export function socketDisconnected() {
  return {
    type: actionTypes.SOCKET_DISCONNECTED,
  };
}
