import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../state/auth/authActions';

function useSignInForm(initialState, validate) {
  const dispatch = useDispatch();
  const { isLoading: isSubmitting, error: authError } = useSelector(
    state => state.auth
  );

  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const hasSubmittedOnce = useRef(false);

  function handleChange(event) {
    const { name, value } = event.target;
    setValues(oldValues => ({
      ...oldValues,
      [name]: value,
    }));
  }

  useEffect(() => {
    if (hasSubmittedOnce.current) {
      const validationErrors = validate(values);
      setErrors(validationErrors);
    }
  }, [values, setErrors, validate]);

  function handleSubmit(onSubmit) {
    return event => {
      hasSubmittedOnce.current = true;
      event.preventDefault();
      const validationErrors = validate(values);
      setErrors(validationErrors);
      const noErrors = Object.keys(validationErrors).length === 0;
      if (noErrors) {
        onSubmit?.();
        dispatch(signIn(values));
      }
    };
  }

  return {
    handleChange,
    handleSubmit,
    errors,
    authError,
    values,
    isSubmitting,
  };
}

export default useSignInForm;
