import React from 'react';
import useContactForm from './useContactForm';
import contactFormValidation from './contactFormValidation';
import styles from './ContactForm.module.css';

function ContactForm({ onSubmit }) {
  const {
    handleChange,
    handleSubmit,
    values,
    error,
    validationErrors,
    isSubmitting,
  } = useContactForm(contactFormValidation);

  return (
    <div className={styles.container}>
      <h1>Fale conosco</h1>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>
            Seu nome
            <input
              type="text"
              name="name"
              placeholder="Insira o seu nome completo"
              autoComplete="off"
              value={values.name}
              onChange={handleChange}
            />
          </label>

          {validationErrors.name && (
            <span className={styles.input_error} role="alert">
              {validationErrors.name}
            </span>
          )}
        </div>

        <div>
          <label>
            Seu email
            <input
              type="text"
              name="email"
              placeholder="Insira seu email"
              autoComplete="off"
              value={values.email}
              onChange={handleChange}
            />
          </label>

          {validationErrors.email && (
            <span className={styles.input_error} role="alert">
              {validationErrors.email}
            </span>
          )}
        </div>

        <div>
          <label>
            Assunto
            <input
              type="text"
              name="subject"
              placeholder="Assunto"
              autoComplete="off"
              value={values.subject}
              onChange={handleChange}
            />
          </label>

          {validationErrors.subject && (
            <span className={styles.input_error} role="alert">
              {validationErrors.subject}
            </span>
          )}
        </div>

        <div>
          <label>
            Mensagem
            <textarea
              name="message"
              placeholder="Escreva sua mensagem"
              value={values.message}
              onChange={handleChange}
            />
          </label>

          {validationErrors.message && (
            <span className={styles.input_error} role="alert">
              {validationErrors.message}
            </span>
          )}
        </div>

        {error && (
          <span className={styles.error_message} role="alert">
            {error}
          </span>
        )}

        <button type="submit" disabled={isSubmitting}>
          Enviar
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
