import React from 'react';
import CaseSharingRequest from './components/CaseSharingRequest';
import useCaseSharingRequestList from './useCaseSharingRequestList';

import styles from './caseSharingRequestList.module.css';

const CaseSharingRequestList = ({ caseSharingRequestList }) => {
  const { caseSharingRequestHandler } = useCaseSharingRequestList();

  return (
    <div className={styles.case_sharing_request_list}>
      <div className={styles.header}>
        <p>Nome do Projeto</p>
        <p>Nome do Caso</p>
        <p>Descrição do Caso</p>
        <p>Status</p>
        <p>Usuário de Origem</p>
        <p>Nome do Usuário</p>
        <p>Compartilhado em</p>
      </div>
      <div className={styles.body}>
        {caseSharingRequestList.map(request => (
          <CaseSharingRequest
            key={request.id}
            caseSharingRequest={request}
            caseSharingRequestHandler={caseSharingRequestHandler}
          />
        ))}
      </div>
    </div>
  );
};

export default CaseSharingRequestList;
