export default function validateCaseValues(values) {
  const errors = {};

  if (values.name && values.name.length > 40) {
    errors.name = 'O nome do caso pode ter no máximo 40 caracteres';
  }

  if (!values.name) {
    errors.name = 'Nome obrigatório';
  }

  return errors;
}
