import { configureStore } from '@reduxjs/toolkit';
import socketMiddleware from './middlewares/socketMiddleware';
import staticReducers from './staticReducers';
import createReducer from './reducers';

export default function () {
  const store = configureStore({
    reducer: staticReducers,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(socketMiddleware),
    devTools: process.env.NODE_ENV !== 'production',
  });

  store.asyncReducers = staticReducers;
  store.injectReducer = (key, asyncReducer) => {
    if (!key || asyncReducer[key]) {
      return;
    }
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };
  store.resetReducers = reducers => {
    store.replaceReducer(createReducer(reducers));
  };

  return store;
}
