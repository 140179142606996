import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  switchCurrentTab,
  toggleRightPanel,
} from '../../../state/ui/rightPanel/rightPanelActions';

import styles from './SimulationsHeaderItem.module.css';

const SimulationsHeaderItem = ({ hasSimulationRunning }) => {
  const dispatch = useDispatch();

  const { currentTab, isOpen } = useSelector(state => state.ui.rightPanel);

  return (
    <button
      className={styles.simulations_button}
      type="button"
      onClick={() => {
        if (!isOpen || currentTab === 'simulations') {
          dispatch(toggleRightPanel());
        }
        dispatch(switchCurrentTab('simulations'));
      }}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path id="chartAxis" d="M3 3V27H27" stroke="white" strokeWidth="2.2" />
        <path
          id="chart"
          className={
            hasSimulationRunning ? styles.chart_running_simulation : ''
          }
          d="M7 19L13.5 12.5L19 18L25.5 11.5"
          stroke="white"
          strokeWidth="2.5"
        />
      </svg>
    </button>
  );
};

export default SimulationsHeaderItem;
