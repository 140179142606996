import { lazy } from 'react';
import store from '../state/store';
import staticReducers from '../state/staticReducers';
import HomePage from './HomePage/HomePage';
import DisciplinesPage from './DisciplinesPage/DisciplinesPage';
import CategoriesPage from './CategoriesPage/CategoriesPage';
import ContactPage from './ContactPage/ContactPage';
import FavoritesPage from './FavoritesPage/FavoritesPage';
import ProjectsPage from './ProjectsPage/ProjectsPage';
import CaseSharingRequestsPage from './CaseSharingRequestsPage/CaseSharingRequestsPage';
import UserSettingsPage from './UserSettingsPage/UserSettingsPage';
import ProjectsManagementPage from './ProjectsManagementPage/ProjectsManagementPage';
import SolverPage from './SolverPage/SolverPage';

const AdminPage = lazy(async () => {
  const module = await import('./AdminPage/AdminPage');
  const { default: adminReducer } = await import('../admin/store');

  store.resetReducers(staticReducers);
  store.injectReducer('admin', adminReducer);
  return module;
});

export {
  HomePage,
  DisciplinesPage,
  CategoriesPage,
  ContactPage,
  FavoritesPage,
  ProjectsPage,
  CaseSharingRequestsPage,
  UserSettingsPage,
  ProjectsManagementPage,
  SolverPage,
  AdminPage,
};
