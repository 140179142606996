import * as actionTypes from './solverFeedbackActionTypes';

const initialState = {
  items: [],
  isLoading: false,
  error: null,
};

export default function solverFeedbackReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SOLVER_FEEDBACK_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.FETCH_SOLVER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        items: action.payload.solverFeedbacks,
        isLoading: false,
        error: null,
      };

    case actionTypes.FETCH_SOLVER_FEEDBACK_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.SEND_SOLVER_FEEDBACK_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.SEND_SOLVER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        items: [...state.items, action.payload.solverFeedback],
        isLoading: false,
        error: null,
      };

    case actionTypes.SEND_SOLVER_FEEDBACK_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.REMOVE_SOLVER_FEEDBACK_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.REMOVE_SOLVER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        items: state.items.filter(
          item => item.id !== action.payload.solverFeedbackId
        ),
        isLoading: false,
        error: null,
      };

    case actionTypes.REMOVE_SOLVER_FEEDBACK_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.LIKE_SOLVER_FEEDBACK_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.LIKE_SOLVER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        items: state.items.map(item => {
          if (action.payload.solverFeedback.id === item.id)
            return action.payload.solverFeedback;
          return item;
        }),
        isLoading: false,
        error: null,
      };

    case actionTypes.LIKE_SOLVER_FEEDBACK_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    case actionTypes.DISLIKE_SOLVER_FEEDBACK_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.DISLIKE_SOLVER_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        items: state.items.map(item => {
          if (action.payload.solverFeedback.id === item.id)
            return action.payload.solverFeedback;
          return item;
        }),
        isLoading: false,
        error: null,
      };

    case actionTypes.DISLIKE_SOLVER_FEEDBACK_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
