import * as actionsType from './actionsType';

const initialState = {
  title: '',
};

export default function headerReducer(state = initialState, action) {
  switch (action.type) {
    case actionsType.CLEAR_HEADER_TITLE:
      return {
        ...state,
        title: '',
      };
    case actionsType.SET_HEADER_TITLE:
      return {
        ...state,
        title: action.payload.title,
      };
    default:
      return state;
  }
}
