import React from 'react';
import {
  MdExpandLess as ExpandLessIcon,
  MdExpandMore as ExpandMoreIcon,
  MdFolder as FolderIcon,
  MdFolderOpen as FolderOpenIcon,
  MdEdit as EditIcon,
  MdDelete as DeleteIcon,
} from 'react-icons/md';

import CaseList from './CaseList';
import useProjectItem from './useProjectItem';
import ProjectOrCaseUpdateModal from './ProjectOrCaseUpdateModal';

import styles from './ProjectItem.module.css';
import NewCaseModal from './NewCaseModal';
import ToolTip from '../../../solvers/prodpoco/common/Tooltip';

const ProjectItem = ({ project, handleRemoveProject, addNewCase }) => {
  const {
    showCases,
    setShowCases,
    values,
    handleChange,
    isEditing,
    setIsEditing,
    handleUpdateProject,
    errors,
    isCreatingNewCase,
    setIsCreatingNewCase,
    runMultiplesCases,
    exportSelepoco,
  } = useProjectItem(project);

  return (
    <>
      <div
        className={`${showCases ? styles.project_row_active : ''} ${
          styles.project_row
        }`}
        onClick={() => {
          setShowCases(!showCases);
        }}
        aria-hidden
        data-testid="projectItem"
      >
        <div>
          {showCases && (
            <div className="flex">
              <ExpandLessIcon />
              <FolderOpenIcon />
            </div>
          )}

          {!showCases && (
            <div className="flex">
              <ExpandMoreIcon />
              <FolderIcon />
            </div>
          )}
        </div>
        {showCases ? (
          <div className={styles.project_info_active}>{project.name}</div>
        ) : (
          <ToolTip tooltip={project.name}>
            <div className={styles.project_info}>{project.name}</div>
          </ToolTip>
        )}
        {showCases ? (
          <div className={styles.project_info_active}>
            {project.description}
          </div>
        ) : (
          <ToolTip tooltip={project.description}>
            <div className={styles.project_info}>{project.description}</div>
          </ToolTip>
        )}
        {project.isSelepocoProject ? (
          <div></div>
        ) : (
          <button
            type="button"
            className={styles.icon}
            onClick={e => {
              setIsEditing(true);
              e.stopPropagation();
            }}
            title="Editar"
          >
            <EditIcon />
          </button>
        )}
        <button
          type="button"
          className={styles.icon}
          title="Deletar"
          onClick={e => {
            handleRemoveProject(project);
            e.stopPropagation();
          }}
        >
          <DeleteIcon />
        </button>
      </div>
      <div>
        {showCases && (
          <>
            {addNewCase && (
              <div className={styles.new_case}>
                {project.solver.normalized_solver_name === 'prodpoco' && (
                  <>
                    <button type="button" onClick={exportSelepoco}>
                      Exportar selepoço
                    </button>
                    {!project.isSelepocoProject && (
                      <button
                        className="ml-4"
                        type="button"
                        onClick={() => {
                          runMultiplesCases();
                        }}
                      >
                        Executar casos
                      </button>
                    )}
                  </>
                )}

                {!project.isSelepocoProject && (
                  <button
                    type="button"
                    onClick={() => setIsCreatingNewCase(true)}
                    className="ml-4"
                  >
                    Criar novo caso
                  </button>
                )}
              </div>
            )}
            <CaseList
              cases={project.cases}
              solverName={project.solver.name}
              solverAlias={project.solver.normalized_solver_name}
              isSelepocoProject={project.isSelepocoProject}
            />
          </>
        )}
      </div>
      {isEditing && (
        <ProjectOrCaseUpdateModal
          type="project"
          values={values}
          handleChange={handleChange}
          handleUpdate={handleUpdateProject}
          errors={errors}
          closeModal={() => setIsEditing(false)}
        />
      )}
      {isCreatingNewCase && (
        <NewCaseModal
          projectId={project.id}
          projectName={project.name}
          closeModal={() => setIsCreatingNewCase(false)}
        />
      )}
    </>
  );
};

export default ProjectItem;
