import React from 'react';
import { MdPerson as UserIcon, MdMenu as MenuIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';
import petrofoamLogo from '../../assets/images/petrofoam-logo.svg';
import styles from './Header.module.css';
import HeaderItem from './components/HeaderItem';
import UserDropdownMenu from './components/UserDropdownMenu';
import SimulationsHeaderItem from './components/SimulationsHeaderItem';
import NotificationsHeaderItem from './components/NotificationsHeaderItem';
import useHeader from './useHeader';
import ConnectionStatusHeaderItem from './components/ConnectionStatusHeaderItem';

const Header = ({ onClickMenu }) => {
  const {
    hasSimulationRunning,
    unreadNotifications,
    connectionStatus,
    headerTitle,
  } = useHeader();

  const displayTitle =
    headerTitle.length > 53
      ? `...${headerTitle.slice(headerTitle.length - 53)}`
      : headerTitle;

  return (
    <div className={styles.header}>
      <div className={styles['header-menu-container']}>
        <button
          className={styles['menu-button']}
          type="button"
          onClick={() => onClickMenu?.()}
        >
          <MenuIcon size="100%" />
        </button>
        <div className={styles['logo-container']}>
          <Link to="/">
            <img className={styles.logo} src={petrofoamLogo} alt="PetroFOAM" />
          </Link>
        </div>
      </div>
      <span
        title={headerTitle}
        className={styles['header-case-name-container']}
      >
        {displayTitle}
      </span>
      <div>
        <ul className={styles.header_item}>
          <ConnectionStatusHeaderItem connectionStatus={connectionStatus} />
          <SimulationsHeaderItem hasSimulationRunning={hasSimulationRunning} />
          <NotificationsHeaderItem unreadNotifications={unreadNotifications} />
          <HeaderItem icon={<UserIcon color="white" size="100%" />}>
            <UserDropdownMenu />
          </HeaderItem>
        </ul>
      </div>
    </div>
  );
};

export default Header;
