import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import {
  fetchCategoriesByDiscipline,
  toggleFavorite,
} from '../../state/categories/categoriesActions';
import { Categories, SolverFeatured } from '../../categories';
import classes from './categoriesPage.module.css';

const CategoriesPage = () => {
  const dispatch = useDispatch();

  const categories = useSelector(state => state.categories);
  const { current: currentDiscipline } = useSelector(
    state => state.disciplines
  );

  const solvers = useMemo(
    () => categories.items.map(category => category.solvers).flat(),
    [categories]
  );

  const featuredSolver = useMemo(
    () =>
      solvers.length > 0
        ? solvers[Math.floor(Math.random() * solvers.length)].id
        : null,
    [solvers]
  );

  useEffect(() => {
    dispatch(fetchCategoriesByDiscipline(currentDiscipline));
  }, [currentDiscipline, dispatch]);

  return (
    <div className={classes.container}>
      {featuredSolver && (
        <SolverFeatured
          solver={solvers.find(solver => solver.id === featuredSolver)}
          onFavorite={id => dispatch(toggleFavorite(id))}
        />
      )}
      {solvers.length > 0 && (
        <Categories
          categories={categories}
          toggleFavoriteState={id => dispatch(toggleFavorite(id))}
        />
      )}
      {solvers.length === 0 && (
        <div className={classes['empty-discipline-warning']}>
          Nenhuma categoria foi encontrada para esta disciplina
        </div>
      )}
    </div>
  );
};

export default CategoriesPage;
