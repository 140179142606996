import * as actionTypes from './authActionTypes';
import * as api from '../../api/authApi';

export function signInStarted() {
  return {
    type: actionTypes.SIGNIN_STARTED,
  };
}

export function signInSucceeded(user) {
  return {
    type: actionTypes.SIGNIN_SUCCEEDED,
    payload: {
      user,
    },
  };
}

export function signInFailed(error) {
  return {
    type: actionTypes.SIGNIN_FAILED,
    payload: {
      error,
    },
  };
}

export function signIn(credentials) {
  return async dispatch => {
    dispatch(signInStarted());

    try {
      const data = await api.signInUser(credentials);

      const user = {
        userId: data.userid,
        userName: `${data.user_first_name} ${data.user_last_name}`,
        username: data.username,
        userAvatar: data.image,
        is_staff: data.is_staff,
        token: data.access,
      };
      dispatch(signInSucceeded(user));
    } catch (error) {
      if ('message' in Object.assign(error)) {
        if (
          error.message === 'Failed to fetch' ||
          error.message === 'NetworkError when attempting to fetch resource.' ||
          error.message === 'Network Error'
        ) {
          dispatch(signInFailed('Sem acesso ao Servidor'));
        } else if (error.message.includes('timeout')) {
          dispatch(
            signInFailed(
              'Tempo de requisição excedido. Por favor, tente novamente.'
            )
          );
        } else {
          dispatch(signInFailed(error.message));
        }
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(signInFailed(error.detail));
      } else {
        dispatch(signInFailed(error));
      }
    }
  };
}

export function signOutStarted() {
  return {
    type: actionTypes.SIGNOUT_STARTED,
  };
}

export function signOut() {
  return async dispatch => {
    try {
      await api.signOutUser();
    } catch (error) {
      console.log('Signout error', error);
    } finally {
      dispatch(signOutStarted());
    }
  };
}
