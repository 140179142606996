import { useDispatch } from 'react-redux';
import {
  deleteNotification,
  markNotificationsAsRead,
} from '../../state/ui/rightPanel/rightPanelActions';

const useNotifications = () => {
  const dispatch = useDispatch();

  const markAsRead = id => {
    dispatch(markNotificationsAsRead([id]));
  };

  const handleDeleteNotification = id => {
    dispatch(deleteNotification([id]));
  };

  return { markAsRead, handleDeleteNotification };
};

export default useNotifications;
