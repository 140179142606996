import React, { useState } from 'react';
import {
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';
import ProjectList from '../../projects/ProjectList';

import styles from './ProjectsManagementPage.module.css';
import useProjectsManagementPage from './useProjectsManagementPage';
import NewProjectModal from './components/NewProjectModal';
import SearchField from '../../components/SearchField';
import RemoveProjectModal from '../../components/RemoveProjectModal';

const ProjectsManagementPage = () => {
  const { id: solverId } = useParams();
  const {
    solver,
    solverProjectsFiltered,
    isLoading,
    searchTerm,
    setSearchTerm,
    isCreatingNewProject,
    openModal,
    closeModal,
    SolverIcon,
  } = useProjectsManagementPage(solverId);

  const location = useLocation();
  const navigate = useNavigate();

  const [alertModal, toggleModal] = useState(false);

  const navigateToImportFromSelepoco = () => {
    navigate('/prodpoco/selepoco', {
      state: { from: location },
      replace: true,
    });
  };

  return solver ? (
    <>
      <RemoveProjectModal
        isOpen={alertModal}
        closeModal={() => toggleModal(false)}
      />
      <div
        className={`${styles.projects_management} ${
          solver.id === 1 ? styles.neo : ''
        } ${solver.id === 2 ? styles.prodpoco : ''}`}
      >
        <div className={styles.header}>
          <div className={styles.solver_info}>
            <SolverIcon />
            <div>
              <h1>{solver.name}</h1>
              <p>{solver.category}</p>
            </div>
          </div>
          <button type="button" onClick={openModal}>
            Criar novo projeto
          </button>
          {solver.id === 2 && (
            <button type="button" onClick={navigateToImportFromSelepoco}>
              Importar novo selepoço
            </button>
          )}
          <SearchField
            placeholder="Pesquisar casos do projeto"
            value={searchTerm}
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
        <ProjectList
          projects={solverProjectsFiltered()}
          removeProjectCallback={() => toggleModal(true)}
          isLoading={isLoading}
          addNewCase
        />
      </div>
      {isCreatingNewProject && (
        <NewProjectModal closeModal={closeModal} solverId={solverId} />
      )}
    </>
  ) : (
    <Navigate to="/categories" state={{ from: location }} replace />
  );
};

export default ProjectsManagementPage;
