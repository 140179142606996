import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editCase } from '../../../state/projects/projectsActions';

import validateCaseValues from './validateCaseValues';

const useCaseItem = caseItem => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState({
    name: caseItem.name,
    description: caseItem.description,
  });

  const [readOnly, setReadOnly] = useState(false);
  const simulation = useSelector(state =>
    state.ui.rightPanel.simulations.items.find(
      sim => sim.caseId === Number(caseItem.id)
    )
  );

  useEffect(() => {
    if (
      [
        undefined,
        'FALHA NO PRÉ-PROCESSAMENTO',
        'INTERROMPIDO NO PRÉ-PROCESSAMENTO',
        'PRÉ-PROCESSADO',
        'COMPLETO',
        'INTERROMPIDO',
        'FINALIZANDO',
        'FALHA NA EXECUÇÃO',
      ].includes(simulation?.jobStatus)
    ) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [caseItem, simulation]);

  const handleChange = e => {
    const { name, value } = e.target;

    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  useEffect(() => {
    const validationErrors = validateCaseValues(values);

    setErrors(validationErrors);
  }, [values]);

  const handleUpdateCase = event => {
    event.preventDefault();

    if (Object.keys(errors).length) {
      return;
    }

    dispatch(editCase(caseItem.id, values));

    setIsEditing(false);
  };

  return {
    values,
    handleChange,
    isEditing,
    setIsEditing,
    handleUpdateCase,
    errors,
    readOnly,
  };
};

export default useCaseItem;
