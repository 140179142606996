import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './leftNavPanel.module.css';

import useNavPanel from './useNavPanel';

const LeftNavPanel = ({ collapsed }) => {
  const { disciplines, currentDiscipline, handleChooseDiscipline } =
    useNavPanel();

  return (
    <div
      className={`${styles['left-nav-panel']} ${
        collapsed ? '' : styles['is-open']
      }`}
    >
      <h6 className={styles.separator}>{collapsed ? '' : 'Disciplinas'}</h6>
      {disciplines.map(discipline => (
        <NavLink
          to="/categories"
          // activeClassName={styles.on_categories}
          key={discipline.id}
          className={`${styles.item} ${
            discipline.id === currentDiscipline?.id ? styles.current : ''
          }`}
          onClick={() => handleChooseDiscipline(discipline)}
        >
          {collapsed ? discipline.abbreviation : discipline.name}
        </NavLink>
      ))}
    </div>
  );
};

export default LeftNavPanel;
