import React from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';

import styles from './ProjectOrCaseUpdateModal.module.css';

const ProjectOrCaseUpdateModal = ({
  type,
  values,
  handleChange,
  handleUpdate,
  errors,
  closeModal,
}) => {
  const entityName = type === 'project' ? 'Projeto' : 'Caso';

  const { name, description } = values;

  return (
    <div
      className={styles.modal}
      onClick={closeModal}
      aria-hidden
      data-testid="outsideModal"
    >
      <div
        className={styles.edit_modal}
        onClick={e => e.stopPropagation()}
        aria-hidden
      >
        <form onSubmit={handleUpdate}>
          <CloseIcon className={styles.close_icon} onClick={closeModal} />
          <div className={styles.edit_header}>
            <h1>{`Editar ${entityName}`}</h1>
          </div>
          <p className={styles.inputLabel}>{`Nome do ${entityName}`}</p>
          {type !== 'project' && (
            <input
              className={
                errors.name
                  ? `${styles.text_input} ${styles.text_input_error}`
                  : styles.text_input
              }
              type="text"
              value={name}
              name="name"
              onChange={handleChange}
            />
          )}
          {type === 'project' && (
            <textarea
              className={
                errors.name
                  ? `${styles.text_input} ${styles.text_input_error}`
                  : styles.text_input
              }
              value={name}
              name="name"
              onChange={handleChange}
            />
          )}
          <p className={styles.input_label}>{`Descrição do ${entityName}`}</p>
          <textarea
            className={
              errors.description
                ? `${styles.text_input} ${styles.text_input_error}`
                : styles.text_input
            }
            value={description}
            name="description"
            onChange={handleChange}
          />
          {Object.keys(errors).map(key => (
            <p key={key} className={styles.update_errors}>
              {errors[key]}
            </p>
          ))}
          <div className={styles.buttons_wrapper}>
            <button type="submit">Confirmar</button>
            <button type="button" onClick={closeModal}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProjectOrCaseUpdateModal;
