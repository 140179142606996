import { create, read } from '../common/services/apiHandler';

async function fetchFavorites(authorization) {
  const params = { fields: 'id,solver' };

  const response = await read({
    auth: authorization,
    resource: '/solvers/favorites/',
    params,
  });

  return response;
}

async function fetchSingleFavorite(authorization, favoriteId) {
  const params = { fields: 'id,solver' };

  const response = await read({
    auth: authorization,
    resource: `/favorites/${favoriteId}/`,
    params,
  });

  return response;
}

async function toggleFavoriteSolver(authorization, solverId) {
  const response = await create({
    auth: authorization,
    resource: `/favorites/`,
    body: {
      solver: solverId,
    },
  });

  return response;
}

export { fetchFavorites, fetchSingleFavorite, toggleFavoriteSolver };
