export default function validateProjectUpdate(values) {
  const errors = {};
  const maxNameSize = 1024;

  if (values.name && values.name.length > maxNameSize) {
    errors.name = `O nome do projeto deve ter no máximo ${maxNameSize} caracteres`;
  }

  if (!values.name) {
    errors.name = 'Nome obrigatório';
  }

  if (!values.description) {
    errors.description = 'Descrição obrigatória';
  }

  return errors;
}
