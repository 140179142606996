import * as actionTypes from './solverFeedbackActionTypes';
import * as api from '../../api/solverFeedbackApi';

export function fetchSolverFeedbacksStarted() {
  return {
    type: actionTypes.FETCH_SOLVER_FEEDBACK_STARTED,
  };
}

export function fetchSolverFeedbacksSucceeded(solverFeedbacks) {
  return {
    type: actionTypes.FETCH_SOLVER_FEEDBACK_SUCCEEDED,
    payload: {
      solverFeedbacks,
    },
  };
}

export function fetchSolverFeedbacksFailed(error) {
  return {
    type: actionTypes.FETCH_SOLVER_FEEDBACK_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchSolverFeedbacks(solverId) {
  return async (dispatch, getState) => {
    dispatch(fetchSolverFeedbacksStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.readSolverFeedbacks(
        { token: user.token },
        solverId
      );
      dispatch(fetchSolverFeedbacksSucceeded(data.comments));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchSolverFeedbacksFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchSolverFeedbacksFailed(error.detail));
      } else {
        dispatch(fetchSolverFeedbacksFailed(error.message));
      }
    }
  };
}

export function sendSolverFeedbackStarted() {
  return {
    type: actionTypes.SEND_SOLVER_FEEDBACK_STARTED,
  };
}

export function sendSolverFeedbackSucceeded(solverFeedback) {
  return {
    type: actionTypes.SEND_SOLVER_FEEDBACK_SUCCEEDED,
    payload: {
      solverFeedback,
    },
  };
}
export function sendSolverFeedbackFailed(error) {
  return {
    type: actionTypes.SEND_SOLVER_FEEDBACK_FAILED,
    payload: {
      error,
    },
  };
}

export function sendSolverFeedback(solverId, { type, text, title, rating }) {
  return async (dispatch, getState) => {
    dispatch(sendSolverFeedbackStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.sendSolverFeedback(
        { token: user.token },
        solverId,
        { type, text, title, rating }
      );
      dispatch(sendSolverFeedbackSucceeded(data.comment));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(sendSolverFeedbackFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(sendSolverFeedbackFailed(error.detail));
      } else {
        dispatch(sendSolverFeedbackFailed(error.message));
      }
    }
  };
}

export function removeSolverFeedbackStarted() {
  return {
    type: actionTypes.REMOVE_SOLVER_FEEDBACK_STARTED,
  };
}

export function removeSolverFeedbackSucceeded(solverFeedbackId) {
  return {
    type: actionTypes.REMOVE_SOLVER_FEEDBACK_SUCCEEDED,
    payload: {
      solverFeedbackId,
    },
  };
}
export function removeSolverFeedbackFailed(error) {
  return {
    type: actionTypes.REMOVE_SOLVER_FEEDBACK_FAILED,
    payload: {
      error,
    },
  };
}

export function removeSolverFeedback(solverFeedbackId) {
  return async (dispatch, getState) => {
    dispatch(removeSolverFeedbackStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await api.removeSolverFeedback({ token: user.token }, solverFeedbackId);
      dispatch(removeSolverFeedbackSucceeded(solverFeedbackId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(removeSolverFeedbackFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(removeSolverFeedbackFailed(error.detail));
      } else {
        dispatch(removeSolverFeedbackFailed(error.message));
      }
    }
  };
}

export function likeSolverFeedbackStarted() {
  return {
    type: actionTypes.LIKE_SOLVER_FEEDBACK_STARTED,
  };
}

export function likeSolverFeedbackSucceeded(solverFeedback) {
  return {
    type: actionTypes.LIKE_SOLVER_FEEDBACK_SUCCEEDED,
    payload: {
      solverFeedback,
    },
  };
}
export function likeSolverFeedbackFailed(error) {
  return {
    type: actionTypes.LIKE_SOLVER_FEEDBACK_FAILED,
    payload: {
      error,
    },
  };
}

export function likeSolverFeedback(solverFeedbackId) {
  return async (dispatch, getState) => {
    dispatch(likeSolverFeedbackStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.likeSolverFeedback(
        { token: user.token },
        solverFeedbackId
      );
      dispatch(likeSolverFeedbackSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(likeSolverFeedbackFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(likeSolverFeedbackFailed(error.detail));
      } else {
        dispatch(likeSolverFeedbackFailed(error.message));
      }
    }
  };
}

export function dislikeSolverFeedbackStarted() {
  return {
    type: actionTypes.DISLIKE_SOLVER_FEEDBACK_STARTED,
  };
}

export function dislikeSolverFeedbackSucceeded(solverFeedback) {
  return {
    type: actionTypes.DISLIKE_SOLVER_FEEDBACK_SUCCEEDED,
    payload: {
      solverFeedback,
    },
  };
}
export function dislikeSolverFeedbackFailed(error) {
  return {
    type: actionTypes.DISLIKE_SOLVER_FEEDBACK_FAILED,
    payload: {
      error,
    },
  };
}

export function dislikeSolverFeedback(solverFeedbackId) {
  return async (dispatch, getState) => {
    dispatch(dislikeSolverFeedbackStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.dislikeSolverFeedback(
        { token: user.token },
        solverFeedbackId
      );
      dispatch(dislikeSolverFeedbackSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(dislikeSolverFeedbackFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(dislikeSolverFeedbackFailed(error.detail));
      } else {
        dispatch(dislikeSolverFeedbackFailed(error.message));
      }
    }
  };
}
