import * as actionTypes from './leftPanelActionTypes';

const initialState = {
  collapsed: false,
};

export default function leftPanelReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_LEFT_PANEL:
      return {
        collapsed: !state.collapsed,
      };
    default:
      return state;
  }
}
