import * as actionTypes from './categoriesActionTypes';

function getInitialState() {
  return {
    isLoading: false,
    items: [],
    currentSolver: {},
    error: null,
    isTogglingFavorite: false,
    toggleFavoriteError: null,
  };
}

export default function categoriesReducer(state = getInitialState(), action) {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.FETCH_CATEGORIES_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        items: action.payload.categories.items,
        error: null,
      };
    case actionTypes.FETCH_CATEGORIES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actionTypes.TOGGLE_FAVORITE_STARTED:
      return {
        ...state,
        isTogglingFavorite: true,
      };
    case actionTypes.TOGGLE_FAVORITE_SUCCEEDED:
      return {
        ...state,
        isTogglingFavorite: false,
        items: state.items.map(category => ({
          ...category,
          solvers: category.solvers.map(solver => {
            if (solver.id === action.payload.id) {
              return {
                ...solver,
                is_favorite: !solver.is_favorite,
              };
            }
            return solver;
          }),
        })),
      };
    case actionTypes.TOGGLE_FAVORITE_FAILED:
      return {
        ...state,
        isTogglingFavorite: false,
        toggleFavoriteError: action.payload.error,
      };
    default:
      return state;
  }
}
