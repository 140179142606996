import React from 'react';
import { MdExpandMore as ExpandIcon } from 'react-icons/md';

import useUserSettingsPage from './useUserSettingsPage';
import { AlertMessage } from '../../components/AlertMessage';
import LoadingBar from '../../components/LoadingBar/LoadingBar';

import styles from './UserSettingsPage.module.css';

const UserSettingsPage = () => {
  const {
    disciplines,
    selectedDiscipline,
    clusters,
    selectedCluster,
    changeSettings,
    saveSettings,
    error,
    isLoading,
  } = useUserSettingsPage();

  return (
    <>
      {isLoading === true && <LoadingBar />}
      {isLoading === false && error && (
        <div className="styles.info_bar">
          <AlertMessage content={error} contextualClass="danger" dismissible />
        </div>
      )}
      {isLoading === false && (
        <div className={styles.userSettings}>
          <h1>Configurações do usuário</h1>
          <form onSubmit={saveSettings}>
            <label htmlFor="discipline">
              Disciplina padrão
              <div className={styles.select_container}>
                <select
                  value={selectedDiscipline?.id}
                  name="discipline"
                  id="discipline"
                  onChange={changeSettings}
                >
                  {disciplines.map(discipline => (
                    <option key={discipline.id} value={discipline.id}>
                      {discipline.name}
                    </option>
                  ))}
                </select>
                <ExpandIcon />
              </div>
            </label>
            <label htmlFor="cluster">
              Cluster padrão
              <div className={styles.select_container}>
                <select
                  value={selectedCluster?.id}
                  name="cluster"
                  id="cluster"
                  onChange={changeSettings}
                >
                  {clusters.map(cluster => (
                    <option key={cluster.id} value={cluster.id}>
                      {cluster.name}
                    </option>
                  ))}
                </select>
                <ExpandIcon />
              </div>
            </label>
            {selectedCluster?.has_account_control && (
              <label htmlFor="nameAccount">
                Conta/projeto
                <input
                  name="nameAccount"
                  id="nameAccount"
                  value={selectedCluster?.account_name}
                  onChange={changeSettings}
                />
              </label>
            )}
            <button type="submit" onClick={saveSettings}>
              Confirmar
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default UserSettingsPage;
