/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import {
  MdExpandMore as ExpandIcon,
  MdClose as RemoveIcon,
  MdThumbUp as LikeIcon,
  MdThumbDown as DislikeIcon,
} from 'react-icons/md';
import styles from './FeedbackSection.module.css';

import { ReactComponent as FullStarIcon } from '../../../assets/icons/full_star.svg';
import { ReactComponent as EmptyStarIcon } from '../../../assets/icons/empty_star.svg';
import Rating from '../../../categories/components/Rating';
import useFeedbackSection from './useFeedbackSection';

function FeedbackSection({ solverId }) {
  const {
    userId,
    rating,
    setRating,
    feedbackType,
    setFeedbackType,
    opinion,
    setOpinion,
    tab,
    setTab,
    filteredSolverFeedbacks,
    likeSolverFeedback,
    dislikeSolverFeedback,
    removeSolverFeedback,
    sendSolverFeedback,
  } = useFeedbackSection(solverId);

  return (
    <section className={styles.container}>
      <h3>Avaliações</h3>
      <div>
        Selecione uma nota:
        <div className={styles.rating}>
          <div className={styles.value}>{rating}</div>
          <div className={styles.stars}>
            {[1, 2, 3, 4, 5].map(rate => (
              <button key={rate} type="button" onClick={() => setRating(rate)}>
                {rate <= rating ? <FullStarIcon /> : <EmptyStarIcon />}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div>
        O que gostaria de enviar?
        <div className={styles.select_container}>
          <select
            value={feedbackType}
            onChange={e => setFeedbackType(e.target.value)}
          >
            <option value="A">Avaliação</option>
            <option value="C">Comentário</option>
            <option value="S">Sugestão</option>
          </select>
          <ExpandIcon />
        </div>
      </div>
      {(feedbackType === 'C' || feedbackType === 'S') && (
        <div>
          Nos conte sua opinião:
          <textarea
            className={styles.opinion}
            value={opinion}
            onChange={e => setOpinion(e.target.value)}
            placeholder="Digite sua opinião"
          />
        </div>
      )}
      <button type="button" onClick={sendSolverFeedback}>
        Enviar
      </button>

      <div className={styles.comments_container}>
        <div className={styles.comments_tabs}>
          <button
            className={tab === 'all' ? styles.selected : ''}
            type="button"
            onClick={() => setTab('all')}
          >
            Todos
          </button>
          <button
            className={tab === 'comments' ? styles.selected : ''}
            type="button"
            onClick={() => setTab('comments')}
          >
            Comentários
          </button>
          <button
            className={tab === 'suggestions' ? styles.selected : ''}
            type="button"
            onClick={() => setTab('suggestions')}
          >
            Sugestões
          </button>
        </div>
        <div className={styles.comment_list}>
          {filteredSolverFeedbacks.length === 0 && (
            <div className={styles.empty_comment_list}>
              Não há feedbacks desse tipo
            </div>
          )}
          {filteredSolverFeedbacks.length > 0 &&
            filteredSolverFeedbacks.map(
              ({
                id,
                comment,
                subject,
                rating: commentRating,
                user_liked: userLiked,
                user_disliked: userDisliked,
                likes,
                dislikes,
                created_at: createdAt,
                user,
              }) => (
                <article key={id}>
                  <div className={styles.comment_rating}>
                    <Rating rating={commentRating} />
                  </div>
                  <div className={styles.comment_content}>
                    <div>
                      <div className={styles.comment_info}>
                        <div>{subject}</div>
                        <div className={styles.comment_date}>
                          {`${user.username} - ${new Date(
                            createdAt
                          ).toLocaleDateString('pt-BR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}`}
                        </div>
                      </div>
                      <p>{comment}</p>
                    </div>
                    <div className={styles.comment_actions}>
                      {user.id === userId && (
                        <button
                          title="Apagar comentário"
                          type="button"
                          onClick={() => removeSolverFeedback(id)}
                        >
                          <RemoveIcon />
                        </button>
                      )}

                      <span>{likes}</span>
                      <button
                        className={userLiked ? styles.user_liked : ''}
                        title="Gostei"
                        type="button"
                        onClick={() => likeSolverFeedback(id)}
                      >
                        <LikeIcon />
                      </button>
                      <span>{dislikes}</span>
                      <button
                        className={userDisliked ? styles.user_disliked : ''}
                        title="Não gostei"
                        type="button"
                        onClick={() => dislikeSolverFeedback(id)}
                      >
                        <DislikeIcon />
                      </button>
                    </div>
                  </div>
                </article>
              )
            )}
        </div>
      </div>
    </section>
  );
}

export default FeedbackSection;
