/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjectsByDiscipline } from '../../state/projects/projectsActions';

const useProjectsPage = () => {
  const dispatch = useDispatch();
  const { error, currentProjects, isLoading } = useSelector(
    state => state.projects
  );

  const [searchTerm, setSearchTerm] = useState('');
  const [alertModal, toggleModal] = useState(false);

  useEffect(() => {
    dispatch(fetchProjectsByDiscipline());
  }, [dispatch]);

  const projectsWithCasesFiltered = () => {
    if (searchTerm === '') {
      return currentProjects;
    }

    const filterByProjectOrCaseName = searchResults =>
      searchResults.map(project => {
        const { name, cases } = project;
        const matchWithProjectName = name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        if (matchWithProjectName) {
          return project;
        }

        const filteredCasesBySearchTerm = cases.filter(caseItem =>
          caseItem.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return { ...project, cases: filteredCasesBySearchTerm };
      });

    const filterProjectsWithoutCases = searchResults =>
      searchResults.filter(project => {
        const matchWithProjectName = project.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        if (matchWithProjectName) {
          return project;
        }
        return project.cases.length;
      });

    let searchResults = filterByProjectOrCaseName(currentProjects);
    searchResults = filterProjectsWithoutCases(searchResults);

    return searchResults;
  };

  return {
    error,
    projectsWithCasesFiltered,
    searchTerm,
    setSearchTerm,
    isLoading,
    alertModal,
    toggleModal,
  };
};

export default useProjectsPage;
