import * as actionTypes from './projectsActionTypes';
import * as api from '../../api/projectsApi';
import * as casesApi from '../../api/casesApi';

export function fetchProjectsStarted() {
  return {
    type: actionTypes.FETCH_PROJECTS_STARTED,
  };
}

export function fetchProjectsSucceeded(projects) {
  return {
    type: actionTypes.FETCH_PROJECTS_SUCCEEDED,
    payload: {
      projects,
    },
  };
}

export function fetchProjectsFailed(error) {
  return {
    type: actionTypes.FETCH_PROJECTS_FAILED,
    payload: {
      error,
    },
  };
}

export function fetchProjects() {
  return async (dispatch, getState) => {
    dispatch(fetchProjectsStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.fetchAllProjectsWithCases({ token: user.token });
      const formattedProjects = data.map(project => ({
        ...project,
        isSelepocoProject: project.is_selepoco_project,
        is_selepoco_project: undefined,
      }));

      dispatch(fetchProjectsSucceeded(formattedProjects));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchProjectsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchProjectsFailed(error.detail));
      } else {
        dispatch(
          fetchProjectsFailed('Desculpe, não foi possível obter os projetos.')
        );
      }
    }
  };
}

export function fetchProjectsByDiscipline() {
  return async (dispatch, getState) => {
    dispatch(fetchProjectsStarted());

    const {
      auth: { user },
      disciplines: {
        current: { id },
      },
    } = getState();

    try {
      const data = await api.fetchProjectsByDiscipline(
        { token: user.token },
        id
      );
      const formattedProjects = data.map(project => ({
        ...project,
        isSelepocoProject: project.is_selepoco_project,
        is_selepoco_project: undefined,
      }));

      dispatch(fetchProjectsSucceeded(formattedProjects));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchProjectsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchProjectsFailed(error.detail));
      } else {
        dispatch(
          fetchProjectsFailed('Desculpe, não foi possível obter os projetos.')
        );
      }
    }
  };
}

export function fetchProjectsBySolver(solverId) {
  return async (dispatch, getState) => {
    dispatch(fetchProjectsStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const { projects } = await api.fetchProjectsBySolver(
        { token: user.token },
        solverId
      );

      const formattedProjects = projects.map(project => ({
        ...project,
        isSelepocoProject: project.is_selepoco_project,
        is_selepoco_project: undefined,
      }));

      dispatch(fetchProjectsSucceeded(formattedProjects));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(fetchProjectsFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(fetchProjectsFailed(error.detail));
      } else {
        dispatch(
          fetchProjectsFailed('Desculpe, não foi possível obter os projetos.')
        );
      }
    }
  };
}

export function createProjectStarted() {
  return {
    type: actionTypes.CREATE_PROJECT_STARTED,
  };
}

export function createProjectSucceeded(project) {
  return {
    type: actionTypes.CREATE_PROJECT_SUCCEEDED,
    payload: {
      project,
    },
  };
}

export function createProjectFailed(error) {
  return {
    type: actionTypes.CREATE_PROJECT_FAILED,
    payload: {
      error,
    },
  };
}

export function createProject(solverId, body) {
  return async (dispatch, getState) => {
    dispatch(createProjectStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.createProject(
        { token: user.token },
        solverId,
        body
      );

      dispatch(createProjectSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(createProjectFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(createProjectFailed(error.detail));
      } else {
        dispatch(createProjectFailed('Falha ao criar projeto'));
      }
    }
  };
}

export function createSelepocoStarted() {
  return {
    type: actionTypes.CREATE_SELEPOCO_STARTED,
  };
}

export function createSelepocoSucceeded(project) {
  return {
    type: actionTypes.CREATE_SELEPOCO_SUCCEEDED,
    payload: {
      project,
    },
  };
}

export function createSelepocoFailed(error, errors = [], warnings = []) {
  return {
    type: actionTypes.CREATE_SELEPOCO_FAILED,
    payload: {
      error,
      errors,
      warnings,
    },
  };
}

export function createSelepoco(body) {
  return async (dispatch, getState) => {
    dispatch(createSelepocoStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.createSelepoco({ token: user.token }, body);

      dispatch(createSelepocoSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(createSelepocoFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        let errors = [];
        let warnings = [];
        if (error.errors) {
          errors = error.errors;
        }
        if (error.warnings) {
          warnings = error.warnings;
        }
        dispatch(createSelepocoFailed(error.detail, errors, warnings));
      } else {
        dispatch(createSelepocoFailed('Falha ao criar projeto'));
      }
    }
  };
}

export function deleteProjectStarted() {
  return {
    type: actionTypes.DELETE_PROJECT_STARTED,
  };
}

export function deleteProjectSucceeded(projectId) {
  return {
    type: actionTypes.DELETE_PROJECT_SUCCEEDED,
    payload: {
      projectId,
    },
  };
}

export function deleteProjectFailed(error) {
  return {
    type: actionTypes.DELETE_PROJECT_FAILED,
    payload: {
      error,
    },
  };
}

export function deleteProject(projectId) {
  return async (dispatch, getState) => {
    dispatch(deleteProjectStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await api.deleteProject({ token: user.token }, projectId);

      dispatch(deleteProjectSucceeded(projectId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(deleteProjectFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(deleteProjectFailed(error.detail));
      } else {
        dispatch(deleteProjectFailed(error.message));
      }
    }
  };
}

export function editProjectStarted() {
  return {
    type: actionTypes.EDIT_PROJECT_STARTED,
  };
}

export function editProjectSucceeded(project) {
  return {
    type: actionTypes.EDIT_PROJECT_SUCCEEDED,
    payload: {
      project,
    },
  };
}

export function editProjectFailed(error) {
  return {
    type: actionTypes.EDIT_PROJECT_FAILED,
    payload: {
      error,
    },
  };
}

export function editProject(projectId, { name, description }) {
  return async (dispatch, getState) => {
    dispatch(editProjectStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await api.patchProject({ token: user.token }, projectId, {
        name,
        description,
      });

      dispatch(editProjectSucceeded(data));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(editProjectFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(editProjectFailed(error.detail));
      } else {
        dispatch(editProjectFailed(error.message));
      }
    }
  };
}

export function deleteCaseStarted() {
  return {
    type: actionTypes.DELETE_CASE_STARTED,
  };
}

export function deleteCaseSucceeded(caseId) {
  return {
    type: actionTypes.DELETE_CASE_SUCCEEDED,
    payload: {
      caseId,
    },
  };
}

export function deleteCaseFailed(error) {
  return {
    type: actionTypes.DELETE_CASE_FAILED,
    payload: {
      error,
    },
  };
}

export function deleteCase(caseId) {
  return async (dispatch, getState) => {
    dispatch(deleteCaseStarted());

    const {
      auth: { user },
    } = getState();

    try {
      await casesApi.deleteCase({ token: user.token }, caseId);

      dispatch(deleteCaseSucceeded(caseId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(deleteCaseFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(deleteCaseFailed(error.detail));
      } else {
        dispatch(deleteCaseFailed(error.message));
      }
    }
  };
}

export function editCaseStarted() {
  return {
    type: actionTypes.EDIT_CASE_STARTED,
  };
}

export function editCaseSucceeded(caseItem) {
  return {
    type: actionTypes.EDIT_CASE_SUCCEEDED,
    payload: {
      case: caseItem,
    },
  };
}

export function editCaseFailed(error) {
  return {
    type: actionTypes.EDIT_CASE_FAILED,
    payload: {
      error,
    },
  };
}

export function editCase(caseId, { name, description }) {
  return async (dispatch, getState) => {
    dispatch(editCaseStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await casesApi.patchCase({ token: user.token }, caseId, {
        name,
        description,
      });

      dispatch(editCaseSucceeded(data.case));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(editCaseFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(editCaseFailed(error.detail));
      } else {
        dispatch(editCaseFailed(error.message));
      }
    }
  };
}

export function createCaseStarted() {
  return {
    type: actionTypes.CREATE_CASE_STARTED,
  };
}

export function createCaseSucceeded(aCase, projectId) {
  return {
    type: actionTypes.CREATE_CASE_SUCCEEDED,
    payload: {
      case: aCase,
      projectId,
    },
  };
}

export function createCaseFailed(error) {
  return {
    type: actionTypes.CREATE_CASE_FAILED,
    payload: {
      error,
    },
  };
}

export function createCase(projectId, body) {
  return async (dispatch, getState) => {
    dispatch(createCaseStarted());

    const {
      auth: { user },
    } = getState();

    try {
      const data = await casesApi.createCase(
        { token: user.token },
        projectId,
        body
      );

      dispatch(createCaseSucceeded(data.case, projectId));
    } catch (error) {
      if (
        error &&
        'code' in Object.assign(error) &&
        error.code === 'token_not_valid'
      ) {
        dispatch(createCaseFailed('Token is invalid or expired'));
        dispatch({
          type: 'SIGNOUT_STARTED',
        });
      } else if (error && 'detail' in Object.assign(error)) {
        dispatch(createCaseFailed(error.detail));
      } else {
        dispatch(createCaseFailed(error.message));
      }
    }
  };
}

export function closeNewProjectModal() {
  return {
    type: actionTypes.CLOSE_NEW_PROJECT_MODAL,
  };
}
export function openNewProjectModal() {
  return {
    type: actionTypes.OPEN_NEW_PROJECT_MODAL,
  };
}

export function closeNewSelepocoModal() {
  return {
    type: actionTypes.CLOSE_NEW_SELEPOCO_MODAL,
  };
}
export function openNewSelepocoModal() {
  return {
    type: actionTypes.OPEN_NEW_SELEPOCO_MODAL,
  };
}
