import axios, { axiosPrivate } from '../common/services/axios';

const LOGIN_URL = '/users/login/';
const LOGOUT_URL = 'users/logout/';

async function signInUser(credentials) {
  try {
    const response = await axios.post(LOGIN_URL, JSON.stringify(credentials), {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

async function signOutUser() {
  try {
    const response = await axiosPrivate.get(LOGOUT_URL, {
      withCredentials: true,
    });

    return response?.data;
  } catch (error) {
    if (error?.response) {
      throw error.response.data;
    }

    throw error;
  }
}

export { signInUser, signOutUser };
