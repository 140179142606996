import React, { useRef } from 'react';
import {
  MdSettings as SettingsIcon,
  MdLogout as SignOutIcon,
} from 'react-icons/md';
import DropdownMenuItem from './DropdownMenuItem';

import styles from './UserDropdownMenu.module.css';

import useUserDrowpdownMenu from './useUserDropdownMenu';
import useClickOutside from './useClickOutside';

function UserDropdownMenu({ onClickOutside }) {
  const clickOutsideRef = useRef();

  const { userName, signOutHandler, goToSettings } = useUserDrowpdownMenu();
  useClickOutside(clickOutsideRef, onClickOutside);

  return (
    <div className={styles.user_dropdown_menu} ref={clickOutsideRef}>
      <h4>{userName}</h4>
      <DropdownMenuItem
        leftIcon={<SettingsIcon />}
        handler={() => goToSettings(onClickOutside)}
      >
        Configurações
      </DropdownMenuItem>
      <DropdownMenuItem leftIcon={<SignOutIcon />} handler={signOutHandler}>
        Sair
      </DropdownMenuItem>
    </div>
  );
}

export default UserDropdownMenu;
